.formStack {
  padding: 0px;
}

.cols {
  float: left;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  color: black;
}

.modal {
  outline: none;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
}

.mergeIframe {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 70%;
  background: #901cec;
  border-radius: 0.25rem;
  overflow: hidden;
  z-index: 9 !important;
  top: 15%;
  left: 15%;
  box-shadow: 0px 0px 50px 1px #444;
}

.top {
  flex: 0 0 auto;
  display: flex;
  background: var(--background-color-gradiant-right);
}

.header {
  font-size: 1.5rem;
  font-weight: 400;
  padding: 1rem 1.5rem;
  margin: 0;
}

.middleFrame {
  flex: 1 1 auto;
  overflow-y: scroll;
  height: 500px;
  width: 600px;
  background: var(--middle-blue-color);
  padding: 1rem;
}

.closeButton {
  display: flex;
  flex: 0 0 5rem;
  justify-content: flex-end;
  align-items: center;
  background: var(--background-color-gradiant-right);
}

.buttons {
  display: flex;
  margin-left: auto;
  align-items: center;
  padding: 0 1.5rem;
}

.cancelButton {
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  user-select: none;
  width: 6rem;
  border: 1px solid var(--color_alabaster);
  color: var(--color_alabaster);
  border-color: var(--color_alabaster);
  background: transparent;
  cursor: pointer;
}

.cancelButton:hover {
  opacity: 0.8;
}

.mergeForm {
  display: flex;
  flex-direction: column;
}

.fieldLabel {
  font-size: 20px;
  color: lightblue;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.fieldInput {
  border-radius: 9px;
  width: 100%;
  padding: 15px;
  background-color: lightblue;
  font-size: 20px;
  height: 30px;
  border-width: thin;
}

.submitForm {
  height: 40px;
  border-radius: 10px;
  border-width: thin;
  font-size: 20px;
  width: 100%;
  margin-top: 15px;
  /* color: white; */
  background-color: var(--background-color-gradiant-right);
  border: 1px solid var(--color_alabaster);
  color: var(--color_alabaster);
  border-color: var(--color_alabaster);
  cursor: pointer;
  user-select: none;
}

.submitForm:hover {
  opacity: 0.8;
}

.docHeading {
  color: white;
  margin: 0px 0px 20px 0px;
  border-bottom: 2px solid white;
}

.submit-msg {
  flex: 1 1 auto;
  overflow-y: scroll;
  height: 500px;
  width: 600px;
  background: var(--background-color-gradiant-right);
  padding: 1rem;
  color: greenyellow;
  font-size: 26px;
  padding: 25px;
}
.errorMsg{
  color: red;
  margin: auto;
  font-size: 1.2rem;
	font-family: var(--font-family-Futura-Md-BT);
}