/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
 .controls {
  /* display: inline-flex;
  position: relative; 

*/
   /* top: 30px;
   position: relative; */
   display: flex;
   width: 100%;
  }

/* .controls > div {
  display: flex;
} */

.screen-share-btn {
  height: 1rem;
}

.bw-image {
  filter: grayscale(90%);
  -webkit-filter: grayscale(90%);
}


.removeattendee {
    /*position: absolute;
    top: -6rem;
    right: 1px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px;    
}

.removeattendee .user{
    background: #fff;
    margin-left: 5px;
    border-radius: 3px;
    padding: 2px;
    text-align: center;
    width: 26px;
}

.removeattendee .user i{
    color:  #555;
    font-size: 13px;
}

.fullscreenButton {
    padding: 12px;
    background: #0006;
    border-radius: 9px;
    text-align: center;
    bottom: 9.5%;
    left: 23%;
    height: 49px;
    width: 49px;
    border: 1px solid #fff;
    line-height: 25px;
    margin-left: 5px;
    cursor: pointer;
    box-shadow: 2px 4px 4px 0px #11111173;
    -webkit-app-region: no-drag;
}

.button-left-side {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 40%;
 }

.button-left-side img{
  cursor: pointer;
  object-fit: contain;
  width: 2.2vw;
  height: 2.2vw;
}

.button-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20%;

}
.button-center img{
  cursor: pointer;
  object-fit: contain;
  width: 3vw !important;
  height: 3vw !important;
}
.localVideoWrapper {
    /* position: absolute;
    width: 100%;
    /* left: 20px; */
    bottom: 15px;
    z-index: 10 !important;
    /*background: pink;*/
    /* -webkit-app-region: no-drag; */ 
}

.button-white {
        background: #fff;
    border-radius: 3px;
    /* margin: 0 auto; */
    padding: 3px;
    width: 1.5rem;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    height: 1.5rem;
    margin-left: 5px;
    cursor: pointer;
    opacity: .8;
}

.button-center-video {
  display: flex;
  flex-direction: row-reverse;
  /* background: red; */
  /* width: 100%; */
  right: 0;
  position: fixed;
  z-index: 9;
  cursor: pointer;
  top: 0;

}

.button-center-margin{
    float: left;
    margin: 1rem !important;
    -webkit-app-region: no-drag;
    margin-left: 2rem !important;
}

.button-center img, .button-right-side img{
  cursor: pointer;
  object-fit: contain;
  width: 2vw;
  height: 2vw;
}

/* .button-center-video img{
    cursor: pointer;
    margin: 0 auto;
    height: 3rem;
    float: left;
} */

.video-enable {
  margin-top: 3px !important;
  height: 12px !important;
}

.video-disable {
  margin-top: 0px !important;
  height: 18px !important;
}

.audio-enable {
  margin-top: 1px !important;
  height: 16px !important;
  margin-left: 3px !important;
}

.audio-disable {
  margin-top: 0px !important;
  height: 18px !important;
}

.reset-margin-left {
  padding-left: 10%;
}

.button-right-side {
  display: flex;
    align-items: center;
    justify-content: space-around;
    width: 40%;
    
}
.button-right-side button {
    margin-right: 15px;
}

.msgcounter {
    margin: -11px 0 0px 144px;
    float: left;
    position: absolute;
    height: 20px;
    width: auto;
    background: #de1010;
    z-index: 999 !important;
    padding: 6px;
    font-size: 13px;
    border-radius: 50%;
    line-height: 8px;
    font-weight: bold;
}

.controls.screenShareMode {
  width: calc(150px - 5rem);
  /*height: calc(var(--local_video_container_height) - 0.5rem);*/
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.15s;
  position: fixed;
    top: 0.25rem;
    margin-top: 3px;
    margin-left: 80px;
    
}

.controls.screenShareMode:hover {
  opacity: 1 !important;
}

.controls.screenShareMode.videoEnabled {
   opacity: 1; 
}

.controls.screenShareMode.audioMuted {
  opacity: 1;
}

.micMuted {
  display: none;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color_black_medium_opacity);
  text-align: center;
  justify-content: center;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 1rem;
}

.controls.screenShareMode.audioMuted.videoEnabled .micMuted {
  display: flex;
}

.controls.screenShareMode.audioMuted.videoEnabled .muteButton {
  background-color: var(--color_thunderbird);
}

.controls button {
  width: 48px;
  height: 48px;
  backdrop-filter: blur(40px);
  border-radius: 8px;
  text-align: center;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  transition: opacity 0.15s;
  outline: none !important;
  z-index: 1;
}

.muteButton, .videoButton i {
  color:  #fff !important;
}

.controls button.enabled {
  color: var(--color_tundora);
  background: var(--color_alabaster);
}

.controls button:hover {
  opacity: 0.8;
}

.controls.roomMode button + button {
  margin-left: 1rem;
}

.controls.screenShareMode button + button {
  margin-left: 0.5rem;
}

.muteButton {
  width: 48px;
  height: 48px;
  background: #FF3459;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.button {
  width: 48px;
  height: 48px;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.float-left {
    float: left;
}

.red-background{
  background: var(--button-red-ground);

}

.green-background{

}

.white-background{

}

.focusButton {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.videoButton {
  width: 48px;
  height: 48px;
  background: #FF3459;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.shareButton {
  width: 48px;
  height: 48px;
  background: #0FDA24;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.chatButton {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  backdrop-filter: blur(40px);
  border-radius: 8px;
      margin-left: 18px;

}

.shareButton i {
  color: #fff;
}


.endButton > i {
  font-size: 30px;
}

.endButton {
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.settingButton {
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.settingButton i {
  font-size: 1.80rem;
}
.conrolIconDiv{
  width: 4.5vw;
  display: flex;
  justify-content: center;
  height: 4.5vw;
  align-items: center;
  background: rgba(54, 54, 54, 0.6);
  border-radius: 1.5vw;
}
.endMeetingDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6vw;
height: 6vw;

background: #DB4B70;
border-radius: 1.5vw;
}
.user11{
  cursor: pointer;
    width: 50px;
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    background: rgba(54, 54, 54, 0.6);
    border-radius: 6px;
    margin: 1px;
}
.user11 img{
  cursor: pointer;
  object-fit: contain;
  width: 25px;
  height: 25px;
}