.diaplayPopUp {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0.9vw;
  width: 11vw;
  display: grid;
  justify-content: center;
  /* margin-bottom: 10px; */
  padding: 5px;
  position: fixed;
  bottom: 16%;
  /* right: 0; */
  left: 44%;
}

@media (min-height: 900px) {  
  .diaplayPopUp {
      right: 0;
  }
}

.btn-leave {
  background-color: var(--color_primary);
  color: whitesmoke;
  margin-top: 0px;
  width: 10vw;
  padding: 0.5vw;
  border: none;
  cursor: pointer;
  font-size: 1.2vw;
  border-radius: 1.2vw;
  font-family: var(--font-family-Futura-Bk-BT);
}

.btn-end {
  background-color: var(--color_primary);
  color: whitesmoke;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 10vw;
  padding: 0.5vw;
  border: none;
  cursor: pointer;
  font-size: 1.2vw;
  border-radius: 1.2vw;
  font-family: var(--font-family-Futura-Bk-BT);
}

.btn-cancel {
  background-color: dimgray;
  color: white;
  margin-top: 3px;
  padding: 0.5vw;
  cursor: pointer;
  border: none;
  font-size: 1.2vw;
  border-radius: 1.2vw;
  font-family: var(--font-family-Futura-Bk-BT);
}