/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .participants {
  /* position: relative;
  width: 99%;
  height: 131px;
  background: var(--video-background-color);
  border-radius: 6px;
  margin: 0;
  z-index: 0;
  margin-top: 3px; */
  position: relative;
    width: 100%;
    height: 11vw;
    /* top: 2px; */
    background: var(--video-background-color);
    /* border-radius: 20px; */
    margin: 0;
    z-index: 0;
    border-radius: 1.2vw;
    border: 3px solid var(--color_border_color_light);

}
.participantsScreenShareView{
  height: 140px;
  border-radius: 20px;
}
.isTileView{
    position: relative;
  background: var(--video-background-color);
    border-radius: 1.2vw;
  margin: 0;
  z-index: 0;
  margin-top: 3px;
  border:3px solid var(--color_border_color_light);
}
.activeSpeaker {
  border: 2px solid var(--color_secondary) !important;
  border-radius: 1.2vw !important;
}
.activeSpeakerScreenShare{
  border: 2px solid var(--color_secondary) !important;
  border-radius: 20px !important;
}
.rosterMinimize{
  opacity: 0;
}
.participantsActiveSpeaker {
    /* position: fixed;
    width: 84%;
    height: 83%;
    top: 12%;
    background: var(--video-background-color);
    border-radius: 6px;
    margin: 2% !important;
    z-index: 0;
    left: 6%; */
    position: fixed;
    width: 70%;
    height: 82%;
    top: 8%;
    background: var(--video-background-color);
    border-radius: 1.2vw;
    z-index: 0;
    /* margin-top: 3px; */
    right: 18%;
    border: none !important;
    opacity: 1 !important;
    cursor: default;
}
.fullScreenparticipantsActiveSpeaker{
  position: fixed;
  width: 73%;
  height: 83%;
  top: 7%;
  background: var(--video-background-color);
  border-radius: 1.2vw;
  z-index: 0;
  /* margin-top: 3px; */
  left: 21%;
  border: none !important;
  opacity: 1 !important;
}
.fullScreenparticipantsActiveSpeakerWithRightsideEnable{
  position: fixed;
  width: 50%;
  height: 83%;
  top: 7%;
  background: var(--video-background-color);
  border-radius: 6px;
  z-index: 0;
  border: none !important;
  left: 19%;
  opacity: 1 !important;
}
.participantsActiveSpeakerWithRightsideEnable {
    /* position: fixed;
    width: 67%;
    height: 83%;
    top: 12%;
    background: var(--video-background-color);
    border-radius: 6px;
    margin: 2% !important;
    z-index: 0;
    left: 5%; */
    position: fixed;
    width: 50%;
    height: 86%;
    top: 5%;
    background: var(--video-background-color);
    border-radius: 6px;
    z-index: 0;
    border: none !important;
    right: 39%;
    opacity: 1 !important;
    cursor: default;
}
/* 
.margintop {
  margin-top: 3px;
} */


.clientlogo {
  content: '';
  /* width: 160px;
  height: 35px; */
  top: 1px;
  left: 7px;
  position: absolute;
  /*background-image: url('./../public/logo-white.png');*/
  background-repeat: no-repeat;
  z-index: -1;
}

.videocenter {
  /* float: left;
  position: fixed;
  height: 93%;
  width: 95.5%;
  left: 5.5%;
  top: 9%;
  opacity: 1 !important; */
  float: left;
    position: fixed;
    height: 98%;
    width: 92%;
    left: 7%;
    top: 1%;
    opacity: 1 !important;
    cursor: default;
}
.fullScreenvideocenter{
  float: left;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0%;
    top: 0%;
    opacity: 1 !important;
}
.videocenterWithRightsideEnable{
  /* float: left;
  position: fixed;
  height: 91%;
  width: 74%;
  left: 5.5%;
  top: 10%;
  opacity: 1 !important; */
  float: left;
  position: fixed;
  height: 98%;
  width: 71%;
  left: 7%;
  top: 1%;
  opacity: 1 !important;
  cursor: default;
}
.fullScreenvideocenterWithRightsideEnable{
  float: left;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0%;
    top: 0%;
    opacity: 1 !important;
}
.videostart {
  position: fixed;
  overflow-x: auto;
  width: 11vw;
  cursor: move;
  /* background-color: red; */
  overflow-y: scroll;
  max-height: calc(100vh - 155px);
  margin-top: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.videostart::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.videostart {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.videocenter video {
  /* float: left;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2.5vw;
    padding: 2%;
    z-index: 1;
    border: none; */
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border-radius: 30px; */
    padding: 0;
    z-index: 1;
    border: none;
}
.fullScreenvideocenter video{
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: unset;
  padding: 0;
  z-index: 1;
  border: none;
}
.displaynone {
  display: none !important;
}

.right-video-main {
  background: rgba(255, 255, 255, 0.6);
}

.rightmain {
  width: 11vw;
  position: absolute;
  z-index: 99;
  right: 3%;
  top: 3%;
}

.rightmainleft {
  /* margin-right: 2px; */
  float: left;
  display: flex;
  width: 100%;
}
.rightmainleft img{
  object-fit: contain;
  cursor: pointer;
  width: 2vw;
  height: 2vw;
}
.rightmainleftTileView{
  float: right;
    width: 5%;
}


::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

@media (min-width:48rem) {
   :root {
    --fixed-header-height: 10rem;
    --scrolled-header-height: 0rem;
    --root-side-padding: 5.0rem
  }
}

@media (min-width:85rem) {
   :root {
    --root-side-padding: calc((100% - 80rem) / 2)
  }
}
/* @media only screen and (max-height : 990px) {
 
  .videostart  {
  margin-top: calc(2vmax + 1px);
}
  } */
@media (prefers-color-scheme:dark) {
  [data-theme=auto] {
    --secondary-text-color: var(--color_white);
    --primary-text-color: #f3f4f5;
    --accent-color: var(--brand-green);
    --interaction-active-state-color: #f3f4f5;
    --label-text-color: #f0f0f0;
    --subline-text-color: #b8b8b8;
    --interaction-unactive-state-color: #b8b8b8;
    --divider-color: #2b2b2b;
    --elevated-background-color: #1a1a1a;
    --primary-background-color: var(--color_black);
    --active-element-highlight-color: var(--brand-green);
    --active-element-highlight-shadow-filter: drop-shadow(0 0.3125em 1.25em rgba(224, 250, 82, 0.15))
  }
}

[data-theme=enforced-dark] {
  --secondary-text-color: var(--color_white);
  --primary-text-color: #f3f4f5;
  --accent-color: var(--brand-green);
  --interaction-active-state-color: #f3f4f5;
  --label-text-color: #f0f0f0;
  --subline-text-color: #b8b8b8;
  --interaction-unactive-state-color: #b8b8b8;
  --divider-color: #2b2b2b;
  --elevated-background-color: #1a1a1a;
  --primary-background-color: var(--color_black);
  --active-element-highlight-color: var(--brand-green);
  --active-element-highlight-shadow-filter: drop-shadow(0 0.3125em 1.25em rgba(224, 250, 82, 0.15))
}

*,
*::before,
*::after {
  box-sizing: border-box;
  /* z-index: 1 !important; */
}

.rightmain .minimize {
  /* float: right;
  margin-right: 6px; */
  width: 33.33%;
    text-align: left;
    color: grey;
}

.rightmain .maximize {
  /* float: right; */
  width: 33.33%;
  text-align: center;
  color: grey;
}
.rightmain .tileViewIcon{
  /* float: right;
  margin-left: 6px; */
  width: 33.33%;
  text-align: right;
  color: grey;
}
.remoteVideoGroup {
  /* padding: 2%; */
  float: right;
  display: grid;
  /*background: rgba(255, 255, 255, 0.6);*/
  overflow: auto;
}

.remoteVideoGroupScreenShare {
  padding: 1%;
  float: right;
  display: grid;
  overflow: auto;
}

.remoteVideoGroup.roomMode {
  /* margin: 5.3%;
  float: right;
  border-radius: 8px;
  width: 220px;
  position: relative;
  margin-left: 12px; */
  float: right;
  /* border-radius: 8px; */
  /* width: 140px; */
  width: 11vw;
  position: relative;
  margin-top: 3.5vmax;
  height: 100vh;
}


.remoteVideoGroup.tileView {
  /* float: right;
  border-radius: 8px;
  width: calc(96vw);
  position: relative;
  margin-right: 30px;
  height: 84vh;
  margin-top: 2vh; */
  float: left;
    border-radius: 8px;
    width: 100%;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fullScreentileView{
  float: left;
  border-radius: 8px;
  width: 100%;
  position: relative;
  height: 96vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.remoteVideoGroup.tileViewRightBarEnabled{
  float: left;
  border-radius: 8px;
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fullScreentileViewRightBarEnabled{
  float: left;
  border-radius: 8px;
  width: 100%;
  position: relative;
  height: 96vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.marginZindex {
    z-index: 1;
}


.videoTileView{
  border-radius: 8px;
  width: 100%;
  /* position: relative; */
}

.attendeeTileView{
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.remoteVideoGroupScreenShare.roomModeScreenShare {
  margin: 0;
  float: left;
  width: 220px;
  overflow-y: scroll;
  height: 275px;
  position: relative;
  margin-left: 0;
}

.remoteVideoGroup.roomMode.isContentShareEnabled {
  /*height: var(--local_video_container_height);*/
}

.remoteVideoGroup.screenShareMode {
  padding: 0 0.25rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(8, 1fr);
}


/* Room mode */

.remoteVideoGroup.roomMode.remoteVideoGroup-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  height: 100%;
}

.remoteVideoGroup.roomMode.remoteVideoGroup-2,
.remoteVideoGroup.roomMode.remoteVideoGroup-3,
.remoteVideoGroup.roomMode.remoteVideoGroup-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 1%;
}

.remoteVideoGroup.roomMode.remoteVideoGroup-5,
.remoteVideoGroup.roomMode.remoteVideoGroup-6 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.remoteVideoGroup.roomMode.remoteVideoGroup-7,
.remoteVideoGroup.roomMode.remoteVideoGroup-8,
.remoteVideoGroup.roomMode.remoteVideoGroup-9 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.remoteVideoGroup.roomMode.remoteVideoGroup-10,
.remoteVideoGroup.roomMode.remoteVideoGroup-11,
.remoteVideoGroup.roomMode.remoteVideoGroup-12 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.remoteVideoGroup.roomMode.remoteVideoGroup-13,
.remoteVideoGroup.roomMode.remoteVideoGroup-14,
.remoteVideoGroup.roomMode.remoteVideoGroup-15,
.remoteVideoGroup.roomMode.remoteVideoGroup-16 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}


/* Content share in room mode */

.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-0 {
  display: none;
}

.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-1,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-2,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-3,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-5,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-6,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-7,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-8,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-9,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-10,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-11,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-12,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-13,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-14,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-15,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-16 {
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
}


/* Screen share mode */

.remoteVideoGroup.screenShareMode.remoteVideoGroup-1,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-2,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-3,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-4 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
}


/* Child elements */

.instruction {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--color_white);
}

.remoteVideoGroup.screenShareMode .instruction {
  font-size: 1rem;
}