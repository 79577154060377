/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */


/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');*/

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #bbb;
	opacity: 1;
	/* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #bbb;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #bbb;
}

.scheduleMeetingForm {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: var(--color_mine_shaft_dark);
	/*background: -moz-linear-gradient(top, #901cec 0%, #5107a0 100%);
  background: -webkit-linear-gradient(top, #901cec 0%,#5107a0 100%);
  background: linear-gradient(to bottom, #901cec 0%,#5107a0 100%);*/
	/* background: var(--color_mine_shaft_light); */
}
.mobileScheduleMeetingForm{
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: 100%; */
	color: var(--color_mine_shaft_dark);	
}

.logo {
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-bottom: 20px;
	/* width: 160px;
  height: 33px; */
}

.versionNumber {
	color: var(--color_black);
	margin-top: -20px;
	font-size: smaller;
	margin-bottom: 10px;
}

.Schedule_formWrapper {
	position: relative;
	/* width: 355px; */
	width: auto;
	height: auto;
	border-radius: 12px;
	/* background: var(--color_white); */
	border-radius: 12px;
	color: var(--color_white);
	/* padding: 2%; */
	text-align: center;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	box-shadow: 0px 0px 100px 1px #ffffff82;
	-webkit-app-region: no-drag !important;
}

.Schedule_form {
	width: 100%;
	display: flex;
	/* display: flex; */
	/* padding-inline: 20px; */
	margin-top: 3rem;
	left: initial;
	justify-content: center;
	/* align-items: center; */
}
.mobileSchedule_form{
	width: 100%;
	display: flex;
	/* display: flex; */
	/* padding-inline: 20px; */
	margin-top: 3rem;
	left: initial;
	justify-content: center;
	flex-direction: column;
}

/* .form form {
  float: left;
} */

.Schedule_titleInput,
.Schedule_nameInput {
	width: 350px;
    height: 55px;
    background: var(--color_white);
    border-radius: 20px;
    /* margin: 0.5rem 0 0 0; */
    padding: 0.75rem 1rem;
    /* font-size: 1rem; */
    border: none;
    box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 24px;
    display: flex;
    color: var(--color_light_grey);
    /* border-top: none; */
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 1.2rem;
	/* display: block;
	background: var(--color_white);
	margin: .5rem;
	padding: 0.75rem 1rem;
	width: 350px;
	height: 55px;
	border-radius: 20px;
	font-size: 1rem;
	border: 1px solid var(--color_alto);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-size: 15px;
	align-items: center;
	text-align: center;
	border-top: none; */
}

.Schedule_titleInput:focus,
.Schedule_nameInput:focus {
	color: #929090;
}

.Schedule_button {
	border: none;
	width: 350px;
	height: 55px;
	background: var(--color_primary);
	border-radius: 20px;
	font-size: 1.1rem;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: normal;
	cursor: pointer;
	transition: background-color 0.15s;
	user-select: none;
	color: var(--color_white);
	align-items: center;
	text-align: center;
  margin: .5rem;
	outline: 0;
  margin-top: 3rem;
}

.disabled_button{
	border: none;
	width: 350px;
	height: 55px;
	background: #9ca3ac;
	border-radius: 20px;
	font-size: 1.1rem;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: normal;
	cursor: pointer;
	transition: background-color 0.15s;
	user-select: none;
	color: var(--color_white);
	align-items: center;
	text-align: center;
  margin: .5rem;
	outline: 0;
  margin-top: 3rem;
}

.Invite_button {
	border: none;
	width: 350px;
	height: 55px;
	background: var(--color_secondary);
	border-radius: 20px;
	font-size: 1.1rem;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: normal;
	cursor: pointer;
	transition: background-color 0.15s;
	user-select: none;
	color: var(--color_white);
	align-items: center;
	text-align: center;
	margin: .5rem;
	outline: 0;
  margin-top: 3rem;
}

.Invite_button:hover {
	background: #1eafa8 !important;
	/* color: #2d99e5;
  border: 2px solid #2d99e5; */
}

.clearButton:hover {
	background: #2d99e5;
	color: var(--color_white);
}

.Schedule_loginLink {
	display: inline-block;
	color: var(--color_white);
	/* background: var(--color_alabaster); */
	cursor: pointer;
	transition: opacity 0.15s;
	user-select: none;
	text-decoration: none;
	margin: 2rem;
}

.Schedule_loginLinkBlackColor {
	color: #555 !important;
	text-decoration: none !important;
}

.Schedule_loginLink:hover {
	text-decoration: underline;
}

.Schedule_leftSide {
	float: left;
	color: #555;
}

.Schedule_regionsList {
	/* margin-bottom: 0.75rem; */
	border-radius: 20px !important;
	/* border: 1px solid var(--color_alto); */
	display: flex;
	flex-direction: column;
	/* width: 330px; */
}

.Schedule_errorMessage {
	color: var(--color_thunderbird);
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
	padding: 5px 5px 10px 5px;
	font-size: 0.8rem;
	font-family: helvetica;
	display: flex;
	justify-content: center;
}

.createMeetingLabel {
	/* border-bottom: 1px solid #ccc; */
	/* padding-bottom: 15px; */
	width: 100%;
	font-size: 1.8rem;
	font-family: var(--font-family-Futura-Md-BT);
	font-style: normal;
	font-weight: normal;
	line-height: 24px;
	color: var(--color_primary);
	align-items: center;
	text-align: center;
}

.meetingId {
	color: var(--color_black);
	float: left;
	margin-top: 5px;
	/* width: 99%; */
}

.formLabel {
	display: flex;
	font-family: var(--font-family-Futura-Md-BT);
	font-style: normal;
	font-weight: normal;
	font-size: 1.1rem;
	line-height: 24px;
	color: var(--color_primary);
	margin: .5rem;
  width: 350px;
}

.text-field-box {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	/* width: 49%; */
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.dateTimePiker {
	display: flex;
	width: 100%;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: space-between; */
	color: gray;
	/* margin-top: 10px; */
}

.dateTimeDoneStart {
  position: absolute;
  margin-top: 105px;
  /* bottom: 6%; */
  /* left: 319px; */
  right: 15px;
  float: right;
  z-index: 1;
  border: none;
  width: 30px;
  height: 30px;
  background: #f03b3bd9;
  border-radius: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  color: var(--color_white);
}

.dateTimeDoneEnd {
  position: absolute;
  margin-top: 105px;
  /* bottom: 6%; */
  /* left: 319px; */
  right: 15px;
  float: right;
  z-index: 1;
  border: none;
  width: 30px;
  height: 30px;
  background: #f03b3bd9;
  border-radius: 30px;
  font-size: 1.1rem;
  cursor: pointer;
  color: var(--color_white);
}

.optinListContainer {
	color: var(--color_black) !important;
}

.multirow {
	display: flex;
	font-family: var(--font-family-Futura-Md-BT);
	font-style: normal;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 24px;
	color: var(--color_primary);
	margin: .5rem;
  margin-top: 3rem;
	width: 350px;
	height: 55px;
  align-items: center;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
 
}

.left-row {
	/* width: 50%; */
  text-align: left;
  /* padding-left: .5rem; */
}

.right-row {
	/* width: 50%; */
  text-align: right;
}

.multiSelect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* width: 330px; */
	height: 55px;
	color: gray;
	border: 1px solid var(--color_alto);
	/* padding: 5px; */
	border-radius: 6px;
	margin-top: 13px;
	border-top: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
}
.inviteParticipants{
	/* padding: 0.75rem 0.75rem; */
}
.ScheduleinviteParticipants{
	position: relative;
}
.participant {
	margin-top: 10px;
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
	/* border: 1px solid gray; */
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
}

.participant-input {
	height: 40px;
    display: block;
    background: var(--color_white);
    border-radius: 6px;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: 1px solid var(--color_alto);
    background-color: transparent;
    font-family: var(--font-family-Futura-Bk-BT);
    display: flex;
    align-items: center;
    color: #929090;
    border-top: none;
    width: 100%;
}

.participant-btn {
	border: none;
}

.custom {
    height: 120px;
    background: var(--color_white);
    border-radius: 20px;
    border: 1px solid var(--color_alto);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #929090;
    border-top: none;
}

.mail-error {
	color: tomato;
	display: inline;
	font-size: 13px;
}

.emailDropDown {
	max-height: 140px;
	width: 370px;
	overflow-y: scroll;
	overflow-x: hidden;
	position: absolute;
	top:75px;
	/* margin-top: -245px; */
	/* bottom: 34%; */
	background-color: var(--color_white);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.emailDropDown::-webkit-scrollbar {
	width: 10px;
}

.emailDropDown::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.emailDropDown::-webkit-scrollbar-thumb {
	background: #888;
}

.underList {
	/* margin-top: 4px;
  min-height: 30px;
  color: deepskyblue;
  left: 0;
  text-align: left;
  padding: 10px 1px 1px 10px;
  font-family: helvetica; */
	color: var(--color_primary);
	padding: 5px 10px;
	text-decoration: none;
	display: block;
	background-color: #F6F6F6;
	font-size: 14px;
	font-family: var(--font-family-Futura-Bk-BT);
	border-bottom: 1px solid #d5d3d3;
}

.underList:hover {
	background-color: #2d99e5 !important;
	color: var(--color_white);
}

.underList:focus {
	background-color: #2d99e5 !important;
	color: var(--color_white);
}

.underList:active {
	background-color: #2d99e5 !important;
	color: var(--color_white);
}

.item {
	border: 2px solid var(--color_white);
	padding: 1px 4px;
	color: var(--color_white);
	background-image: linear-gradient(#2d99e5, #1e7cbee3);
	font-size: 13px;
	display: block;
	word-break: break-all;
	float: left;
	min-height: 30px;
	line-height: 24px;
	/* margin: 1%; */
	font-family: var(--font-family-Futura-Md-BT);
}

.listItems {
	display: flex;
}

.list {
	display: flex;
	/* word-break: break-all; */
	width: 370px;
	/* overflow-wrap: break-word; */
	flex-wrap: wrap;
	max-height: 65px;
	overflow: auto;
	padding: 1%;
}

.emailListing {
	display: flex;
}

.mail-error {
	color: red;
	font-family: helvetica;
}

.delete {
	cursor: pointer;
	margin-left: 5px;
}

.delete:hover {
	color: red;
}

.btnDiv {
	display: flex;
}

.meeting_spinner {
	margin: auto;
	width: 20px;
	height: 20px;
	position: relative;
	/* margin-top: 3%;
  margin-left: 145px; */
}

.meeting_circle {
	width: 100%;
	height: 100%;
	position: absolute;
}

.meeting_circle::before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: var(--color_white);
	border-radius: 100%;
	animation: circleFadeDelay 1.2s infinite ease-in-out both;
}

.meeting_circle2 {
	transform: rotate(30deg);
}

.meeting_circle3 {
	transform: rotate(60deg);
}

.meeting_circle4 {
	transform: rotate(90deg);
}

.meeting_circle5 {
	transform: rotate(120deg);
}

.meeting_circle6 {
	transform: rotate(150deg);
}

.meeting_circle7 {
	transform: rotate(180deg);
}

.meeting_circle8 {
	transform: rotate(210deg);
}

.meeting_circle9 {
	transform: rotate(240deg);
}

.meeting_circle10 {
	transform: rotate(270deg);
}

.meeting_circle11 {
	transform: rotate(300deg);
}

.meeting_circle12 {
	transform: rotate(330deg);
}

.meeting_circle2::before {
	animation-delay: -1.1s;
}

.meeting_circle3::before {
	animation-delay: -1s;
}

.meeting_circle4::before {
	animation-delay: -0.9s;
}

.meeting_circle5::before {
	animation-delay: -0.8s;
}

.meeting_circle6::before {
	animation-delay: -0.7s;
}

.meeting_circle7::before {
	animation-delay: -0.6s;
}

.meeting_circle8::before {
	animation-delay: -0.5s;
}

.meeting_circle9::before {
	animation-delay: -0.4s;
}

.meeting_circle10::before {
	animation-delay: -0.3s;
}

.meeting_circle11::before {
	animation-delay: -0.2s;
}

.meeting_circle12::before {
	animation-delay: -0.1s;
}

@keyframes circleFadeDelay {
	0%,
	39%,
	100% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
}

.datePickerInput {
	width: 350px;
    height: 55px;
    background: var(--color_white);
    border-radius: 20px;
    /* margin: 0.5rem 0 0 0; */
    padding: 0.75rem 1rem;
    /* font-size: 1rem; */
    border: none;
    box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 24px;
    display: flex;
    color: var(--color_light_grey);
    /* border-top: none; */
    margin-left: auto;
    margin-right: auto;
    /* margin-bottom: 1.2rem;
	/* display: block;
	background: var(--color_white);
  margin: .5rem;
  padding: 0.75rem 2rem;
  width: 350px;
  height: 55px;
	border-radius: 20px;
	border: 1px solid var(--color_alto);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
	display: flex;
	align-items: center;
	text-align: left;
	border-top: none;
	color: #9BABC5;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px; */
  
}

.dropbtn {
	background-color: #04AA6D;
	color: var(--color_white);
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
	background-color: #3E8E41;
}

#myInput {
	box-sizing: border-box;
	/* background-image: url('searchicon.png'); */
	background-position: 14px 12px;
	background-repeat: no-repeat;
	font-size: 16px;
	padding: 14px 20px 12px 45px;
	border: none;
	border-bottom: 1px solid #ddd;
}

#myInput:focus {
	outline: 3px solid #ddd;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-input {
	width: 350px;
    height: 55px;
    background: var(--color_white);
    border-radius: 20px;
    /* margin: 0.5rem 0 0 0; */
    padding: 0.75rem 1rem;
    /* font-size: 1rem; */
    border: none;
    box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 24px;
    display: flex;
    color: var(--color_light_grey);
    /* border-top: none; */
    margin-left: auto;
    margin-right: auto;
 }

.dropdown-content {
	margin-top: 100px;
    /* display: none; */
    position: absolute;
    background-color: var(--color_white);
    width: 355px;
    overflow: auto;
    color: var(--color_light_grey);
    max-height: 157px;
    border: 1px solid var(--color_light_grey);
    z-index: 1;
}

.dropdown-content::-webkit-scrollbar {
	width: 10px;
}

.dropdown-content::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.dropdown-content::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.dropdown-content :focus {
	background-color: var(--color_primary);
}

.dropdown-content .dropdown-key {
	color: var(--color_light_grey);
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    /* text-align: left; */
    font-size: 14px;
    font-family: var(--font-family-Futura-Bk-BT);
    border-bottom: 1px solid var(--color_light_grey);
}

.dropdown-content .dropdown-key:hover {
	background-color: var(--color_primary) !important;
	color: var(--color_white)
}

.dropdown-content .dropdown-key:focus {
	background-color: var(--color_primary) !important;
	color: var(--color_white)
}

.dropdown-content .dropdown-key:active {
	background-color: var(--color_primary) !important;
	color: var(--color_white)
}

.doc-dropdown-content {
	/* margin-top: 45px; */
	/* display: none; */
	position: absolute;
	background-color: #F6F6F6;
	min-width: 330px;
	overflow: auto;
	border: 1px solid #ddd;
	z-index: 1;
	max-height: 157px;
	overflow-y: scroll;
}

.doc-dropdown-content a {
	color: var(--color_black);
	padding: 5px 10px;
	text-decoration: none;
	display: block;
	/* text-align: left; */
	font-size: 14px;
	font-family: helvetica;
	border-bottom: 1px solid #d5d3d3;
}

.doc-dropdown-content a:hover {
	background-color: #2d99e5 !important;
	color: var(--color_white)
}

.doc-dropdown-content a:focus {
	background-color: #2d99e5;
	color: var(--color_white)
}

.doc-dropdown-content a:active {
	background-color: #2d99e5;
	color: var(--color_white)
}

.video-dropdown-content {
	/* margin-top: 45px; */
	/* display: none; */
	position: absolute;
	background-color: #F6F6F6;
	min-width: 330px;
	overflow: auto;
	max-height: 157px;
	border: 1px solid #ddd;
	z-index: 1;
}

.video-dropdown-content a {
	color: var(--color_black);
	padding: 5px 10px;
	text-decoration: none;
	display: block;
	/* text-align: left; */
	font-size: 14px;
	font-family: helvetica;
	border-bottom: 1px solid #d5d3d3;
}

.video-dropdown-content a:hover {
	background-color: #2d99e5;
	color: var(--color_white)
}

.video-dropdown-content a:focus {
	background-color: #2d99e5;
	color: var(--color_white)
}

.video-dropdown-content a:active {
	background-color: #2d99e5;
	color: var(--color_white)
}

.video-input {
	width: 100%;
	border: none;
	font-size: 15px;
	color: #929090;
}

.doc-input {
	width: 100%;
	border: none;
	font-size: 15px;
	color: #929090;
}

.show {
	display: block;
}

.selectedDoc {
	display: flex;
	flex-direction: row;
}

.selectedDocParentDiv {
	display: flex;
	flex-direction: row;
	border: 2px solid var(--color_white);
	padding: 1px 4px;
	/* color: var(--color_white);
  background-image: linear-gradient(#2d99e5, #1e7cbee3); */
	font-size: 14px;
	display: block;
	word-break: break-all;
	float: left;
	font-family: Arial, Helvetica, sans-serif;
	height: 30px;
	line-height: 24px;
	border-radius: 4px;
	margin: 1%;
}

.selectedVodParentDiv {
	display: flex;
	flex-direction: row;
	border: 2px solid var(--color_white);
	padding: 1px 4px;
	color: var(--color_white);
	background-image: linear-gradient(#2d99e5, #1e7cbee3);
	font-size: 14px;
	display: block;
	word-break: break-all;
	float: left;
	font-family: Arial, Helvetica, sans-serif;
	height: 30px;
	line-height: 24px;
	border-radius: 4px;
	margin: 1%;
}

.selectedDocKey {
	/* background-color: lightseagreen; */
	color: var(--color_white);
}

.removeKey {
	margin-left: 5px;
	cursor: pointer;
}

.removeKey:hover {
	color: red;
}

.videoParentDiv {
	display: flex;
	flex-direction: column;
}

.datePickerWrapper {
	left: 10px !important;
}
.scheduleMeeting_InviteParticipants_span{
	font-size: 12px;
}