.deviceSettingContainer {
  display: block;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.videoPreview {
  /* width: 393px !important; */
  /* height: 193px !important; */
  margin: 0vw 0 0.5vw;
  min-height: 13.5vw;
  border-radius: 15px !important;
}

.dropdown {
  margin-bottom: .75vw;
  width: 100%;
}

.form_label {
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 3vh;
  display: flex;
  align-items: center;
  color: var(--color_primary);

}

.deviceList {
  padding: 1rem;
  /*margin-top: -2% !important;*/
  /*float: left;*/
  width: 100%;
}

.device-list {
  width: 100%;
  margin-top: 15px;
}

.device-list .device-name {
  float: left;
  width: 100%;
  height: 24px;
  font-weight: bold;
  margin: .5rem;
  font-size: 15px;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  /* font-size: 20px; */
  line-height: 24px;
  color: var(--color_primary);

}

.control {
  cursor: pointer;
  outline: none !important;
  box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
  transition: none;
  border-radius: .9rem;
  padding: 0.5rem;
  border: none;
  height: 3rem;
  line-height: 2rem;
  margin-top: 10px;
  /* width: 100%; */
  display: block;
  background: #ffffff;
  margin: 0;
   /* width: 350px; */
  font-size: 1rem;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  align-items: center;
  /* text-align: center; */
  border-top: none;
  color: var(--color_light_grey);
 }

.placeholder {
  /*color: var(--color_alto1);*/
  color: var(--color_light_grey);
  width: 93%;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
}

.arrow {
  border-color: var(--color_alto) transparent transparent;
  border-width: 0.3rem 0.3rem 0;
  margin-top: 7px;
  margin-right: 0.25rem;
}

.dropdown[class~='is-open'] .arrow {
  border-color: var(--color_alto) transparent transparent !important;
  border-width: 0.3rem 0.3rem 0 !important;
}

.menu {
  margin: 0;
  padding: 0.5rem;
  color: var(--color_alto);
  /*background-color: var(--color_cod_gray_medium);*/
  box-shadow: 0 0.25rem 0.5rem var(--color_black_low_opacity);
  overflow: hidden;
  font-size: 0.9rem;
  border: none;
  max-height: none;
  border-radius: .8rem;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
}

.menu *[class~='Dropdown-option'] {
  /*color: var(--color_silver_chalice);*/
  color: #222;
  /* border-radius: 1.2rem; */
}

.menu *[class~='Dropdown-option']:hover {
  background-color: var(--background-color-gradiant-right);
  color: #fff;
}

.menu *[class~='is-selected'] {
  background-color: transparent;
  /*color: var(--color_alabaster);*/
  color: #c3c0c0;
}

.menu *[class~='is-selected']:hover {
  background-color: var(--background-color-gradiant-right);
  color: #fff;
}