/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .participantStatusSub {
  position: absolute;
  z-index: 8;
  width: 95%;
  background: #0006;
  border-radius: 4px;
  display: table;
  margin: 100px 8px -20px 4px;
}

.participantRosterStatusNew {
  position: absolute;
  left: 2%;
  z-index: 1;
  bottom: 3%;
  opacity: 0;
  transition: all ease-out 1s;
  width: auto;
}

.participantRosterStatusNew:hover{
transition: all ease-out 1s;
opacity: 1 !important;
}
.remoteVideo {
display: none;
position: relative;
background: transparent;
/*overflow: hidden;*/
}

.activeSpeaker {
  border: solid 2px var(--color_secondary);
  border-radius: 20px;
}

.muted {
float: left;
  text-align: left !important;
  position: absolute;
  top: 1px !important;
  left: -6px !important;
  background: #ff0000b8;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;    
}

.attendeeName {
/* position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 16%;
  font-size: 30px;
  text-transform: capitalize; */
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-Futura-Md-BT);
  /* text-align: center; */
  width: 100%;
  height: 100%;
  /* background: pink; */
  color: var(--color_white);
  /* padding: 16%; */
  font-size: 2vw;
  text-transform: capitalize;
}
.attendeeNameScreenShareView{
font-size: 24px;
}
.attendeeNameTileview {
position: absolute;
top: 0;
display: flex;
justify-content: center;
align-items: center;
/* text-align: center; */
width: 100%;
font-family: var(--font-family-Futura-Md-BT);
border-radius: 1vw;
height: 100%;
/* background: pink; */
/* padding: 16%; */
color: var(--color_white);
font-size: 4vw;
text-transform: capitalize;;
}

/* .largeHeight {

  height: 98% !important;
  width: 97% !important;
  vertical-align: middle;
  float: left;
} */

.contentcenter{
  /* font-size: 70px;
  vertical-align: middle;
  padding: 0%;
  margin-top: 39%;
  text-align: center; */
  font-size: 5vw;
  font-family: var(--font-family-Futura-Md-BT);
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0%; */
  /* margin-top: 39%; */
  height: 100%;
  width: 100%;
}
.contentcenterRightSideEnable {
  /* font-size: 5vw;
  vertical-align: middle;
  padding: 0%;
  margin-top: 70%;
  text-align: center; */
  font-size: 5vw;
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0%; */
  /* margin-top: 39%; */
  height: 100%;
  width: 100%;
}
.participantStatusTileView{
position: absolute;
  float: left;
  bottom: 2.5vw;
  width: 100%;
  height: 0;
  z-index: 8;
}
.screenShareViewVideo{
border-radius: 20px !important;
}
.participantnameTileView{
width: 6vw;
height: 2.5vw;
/* left: 188px; */
display: flex;
justify-content: center;
align-items: center;
/* top: 393px; */
background: var(--color_white);
border-radius: 0 1vw 0 1vw;
font-family: var(--font-family-Futura-Md-BT);
font-style: normal;
font-weight: 500;
font-size: 1.2vw;
line-height: 1.2vw;
text-align: center;
color: #434358;
}
.participantControlTileView{
position: absolute;
/* float: right; */
/* bottom: 40px; */
/* top: 0; */
/* right: 0; */
/* left: 45px; */
width: 100%;
height: 0;
z-index: 8;
}
.participantControlDivTileView{
  /* display: flex; */
  /* flex-direction: row; */
  float: right;
  /* position: relative; */
  width: 6vw;
  height: 2.5vw;
  /* left: 188px; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* top: 393px; */
  background: var(--color_white);
  border-radius: 0 1vw 0 1vw;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  color: #434358;
}
.participantStatus {
  /*float: left;
  position: relative;
  display: flex;
  z-index: 1;
  bottom: 26px;
  width: 92%;
  background: #0006;
  border-radius: 4px;*/

  /* position: relative;
  background: var(--video-name-plate-background-color); 
  width: 95%;
  z-index: 9999;
  margin-top: -28px;
  border-radius: 4px;
  margin-left: 2%; */
  float: left;
  position: relative;
  bottom: 40px;
  width: 100%;
  height: 0;
  z-index: 2;
}


.displaynone {
  display: none !important;
}

.remoteVideo.roomMode {
border-radius: 0.25rem;
}

.remoteVideo.screenShareMode {
border-radius: 0.25rem;
}
.remoteVideo.rosterEnabled{
display: block !important;
/* margin: 1%; */
width: 100%;
height: 100%;
position: absolute;
}
.remoteVideo.enabled {
display: block !important;
/* margin: 1%; */
width: 100%;
height: 100%;
/* position: absolute; */
position: relative;
border-radius: 1.2vw;
border: 3px solid var(--color_border_color_light);
}



.video {
display: block;
  position: unset;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 8;
  width: 188px;
  height: 127px;
}

.videonew {
  /* display: block;
  position: unset;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 8;
  width: 214px;
  height: 131px;
  margin: -2px 0 0px -2px; */
  display: block;
  position: unset;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 8;
  border-radius: 1vw;
  width: 100%;
  background: var(--video-background-color);
  height: 100%;
  /* border: 2px solid var(--color_border_color_light); */
}

.ddtt {
  font-size: 30px;
  background: red;
}


.video1 {
display: block;
position: absolute;
top: 0;
left: 0;
width: 50%;
height: 50%;
object-fit: cover;
z-index: 8;
}


.video i {
  color: var(--color_black);
  top: 40%;
  float: left;
  position: absolute;
  left: 4%;
}

.attname {
  color: var(--color_black);
  top: 0%;
  float: left;
  position: absolute;
  left: 4%;
}

.muted {
text-align: center;
flex: 0 0 1.5rem;
font-size: 0.9rem;
margin-left: 0.5rem;
width: 1.5rem;
}

.nameplate {
z-index: 1;
align-items: center;
position: absolute;
max-width: 95%;
left: 50%;
transform: translate(-50%, 0);
background-color: var(--color_black_medium_opacity);
backdrop-filter: blur(0.5rem);
}

.remoteVideo.roomMode .nameplate {
display: flex;
}

.remoteVideo.screenShareMode .nameplate {
display: none;
}

.remoteVideo.medium .nameplate {
padding: 0.25rem 0.3rem 0.25rem 0.5rem;
bottom: 0.25rem;
font-size: 0.8rem;
border-radius: 0.5rem;
}

.remoteVideo.large .nameplate {
padding: 0.5rem 0.75em 0.5em 1rem;
bottom: 1.5rem;
font-size: 1rem;
border-radius: 0.5rem;
z-index: 9;
}

.marginleft {
left: 12%;
}

.fullscreen {
  position: fixed;
  /*z-index: 9;*/
}


.fullscreen1 {
  position: fixed;
  bottom: 19% !important;
  left: 12%;
}

.fullscreenTwo {
position: absolute;
  bottom: 10% !important;
  left: 22%;
}

.displaynone {
  display: none !important;
}

.margintop {
  
  left: 28%;
  margin-top: 7px;
}

.margintop1 {
  left: 100px;
  bottom: 75px !important;
}

.remoteVideo.small .nameplate {
padding: 0.2rem 0.2rem 0.2rem 0.3rem;
bottom: 0.25rem;
font-size: 0.75rem;
border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled .nameplate {
display: none;
}

.remoteVideo.screenShareMode.large .nameplate {
display: flex !important;
padding: 0.2rem 0.2rem 0.2rem 0.3rem;
bottom: 0.25rem;
font-size: 0.75rem;
border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled.large .nameplate {
display: flex !important;
padding: 0.2rem 0.2rem 0.2rem 0.3rem;
bottom: 0.25rem;
font-size: 0.75rem;
border-radius: 0.25rem;
}

/* .name {
flex: 1 1 auto;
white-space: nowrap;
overflow: auto;
text-overflow: ellipsis;
margin-left: 30px;

} */

.nameStatus {
/* height: 23px;
display: flex;   */
    /* height: 23px; */
  /* display: flex; */
  width: 93px;
  height: 40px;
  /* left: 188px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: 393px; */
  background: var(--color_white);
  border-radius: 0px 20px 0px 18px;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #434358;

}


/* .nameStatus div {
display: none;

background: #ff0000b8;
  margin-right: 6px;
  border-radius: 3px;
  width: 50px;
  text-align: center;

} */

/* .nameStatus div i{

color: var(--color_white);
  font-size: 13px;
}

.nameStatus div.name {
background: inherit;
  overflow: hidden;
  width: 100%;
} */



.nameStatusTielView {
  margin: -10px 0 0 0;
  text-transform: capitalize;
  border-radius: 6px;
  margin: 0 auto;
}

.nameStatusTielView .name{
  font-size: 17px;
  height: 30px;
  border-radius: 8px;
  margin-left: 32px !important;
  line-height: 30px;
}


.muted {
flex: 0 0 1.25rem;
width: 1.40rem;
  text-align: center !important;
}

.nameStatusTielView .muted{
  height: 29px;
  font-size: 15px;
}

.nameStatusTielView .muted i{
  margin-top: 7px;
}

.remoteVideo.small .name + .muted {
margin-left: 0.25rem;
text-align: center !important;
}

.remoteVideo.medium .name + .muted {
margin-left: 0.25rem;
text-align: center !important;

}

.remoteVideo.large .name + .muted {
margin-left: 0.5rem;
}

.raisedHand {
z-index: 1;
position: absolute;
top: 0.25rem;
left: 0.25rem;
font-size: 2rem;
animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
transform: translate3d(0, 0, 0);
backface-visibility: hidden;
perspective: 1000px;
user-select: none;
}

@keyframes shake {
10%,
90% {
  transform: translate3d(-0.5px, 0, 0);
}

20%,
80% {
  transform: translate3d(1px, 0, 0);
}

30%,
50%,
70% {
  transform: translate3d(-1.5px, 0, 0);
}

40%,
60% {
  transform: translate3d(1.5px, 0, 0);
}
}
.screenShareViewModeParticipants{
bottom: 40px;
}
.screenShareViewModenameStatus{
width: 93px;
height: 40px;
border-radius: 0 20px 0 20px;
font-size: 12px;
}