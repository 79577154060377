/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

/*video{
 object-fit: cover !important;
}
*/
.mainContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.paraContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  width: -moz-available;          
  width: -webkit-fill-available; 
  /* width: fill-available; */
  background: #F6F9FB;
}
.meetingDetail{
  display: flex;
  height: 12%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* background-color: var(--color_white); */
}
.fullScreenMeetingDetail{
  display: flex;
  position: absolute;
  opacity: 0;
  height: 12%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #F6F9FB;
  transition: all 0.5s ease-out;
  z-index: 0;
}
.fullScreenMeetingDetail:hover{
  opacity: 1;
  transition: all 0.5s ease-out;
  z-index: 10;
}
.meetingIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5vw;
  height: 4.5vw;
  /* left: 161px; */
  /* margin: 1.5vw; */
  /* top: 25px; */
  background: var(--color_primary);
  border-radius: 1.2vw;
  cursor: pointer;
  z-index: 10;
}
.meetingIcon img{
  object-fit: contain;
  width: 2.2vw;
  height: 2.2vw;
}
.meetingId{
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vw;
  height: 100%;
  width: 90%;
  /* height: 10%; */
  display: flex;
  line-height: 36px;
  justify-content: center;
  align-items: center;
  color: #434358;
}
.newheader {
  display: flex;
  flex-direction: column;
  float: left;
  position: relative;
  opacity: 1 !important;
  top: 0;
  /* width: 6.7%; */
  width: 100px;
  /* border-right: 2px solid #E4E9F0; */
  background: var(--color_white);
  z-index: 10;
}
.fullScreenModeHeader{
  display: flex;
  flex-direction: column;
  float: left;
  position: relative;
  top: 0;
  width: 100px;
  background: var(--color_white);
  position: absolute;
  opacity: 0;
  height: 100%;
  z-index: 15;
  transition: all 0.5s ease-out;
}
.fullScreenModeHeader:hover{
  opacity: 1 !important;
  transition: all 0.5s ease-out;
  z-index: 10;
}
.fullScreenModeHeader .logo{
  float: left;
    width: 100%;
    position: relative;
    text-align: center;
    height: 20%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
}
.newheader .logo {
  float: left;
    width: 100%;
    position: relative;
    text-align: center;
    height: 20%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
}
.fullScreenModeHeader .stopwatch{
  color: #bbb;
  font-family: var(--font-family-Futura-Md-BT);
  font-size: 1.2vw;
}
.fullScreenModeHeader .logo img{
  vertical-align: middle;
  margin-top: 25px;
  object-fit: contain;
  width: 4vw;
  height: 4vw;
}
.newheader .logo img{
  vertical-align: middle;
  margin-top: 25px;
  object-fit: contain;
  width: 4vw;
  height: 4vw;
}
.newheader .stopwatch{
  color: #bbb;
  font-family: var(--font-family-Futura-Md-BT);
  font-size: 1.2vw;
}
.fullScreenModeHeader .rightside{
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  line-height: 20px;
  margin-left: 0;
  height: 60%;
  right: 0;
}
.newheader .rightside {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  line-height: 20px;
  margin-left: 0;
  height: 60%;
  right: 0;
}
 .rightsideDivIcon{
  width: 55px;
    height: 55px;
    /* left: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* top: 404px; */
    border-radius: 10px;
}
.rightsideDivIcon:hover{
  background: rgba(155, 171, 197, 0.1);
}
 .isSelected{
  background: var(--color_primary) !important;
}
 .rightsideDivIcon img{
  object-fit: contain;
  width: 25px;
  height: 30px;
}
.rightsideDivIcon span{
  position: absolute;
  width: 3px;
  height: 55px;
  left: 88px;
  /* top: 683px; */
  background: var(--color_primary);
  border-radius: 20px;
}
.fullScreenModeHeader .rightside img{
  cursor: pointer;   
}
.newheader .rightside img{ 
    /* float: left;
    position: relative;
    margin-left: 8%;
    line-height: 20px;
    cursor: pointer;
    margin-top: 4px;
    height: 30px;
    width: 30px;  */
    cursor: pointer;   
}

 .userlogo {
    height: 5vw;
    width: 5vw;
    /* float: left; */
    border-radius: 1.2vw;
    /* margin: 4px 0 0 10px; */
    display: flex;
    font-size: 2vw;
    font-family: var(--font-family-Futura-Md-BT);
    font-weight: bold;
    align-items: center;
    justify-content: center;
    line-height: 29px;
    background: var(--video-background-color);
    border: none;
    text-align: center;
    line-height: 28px;  
    cursor: pointer;  
}

 .menuTab {
  position: fixed;
  z-index: 12;
  background-color: #E4E9F0;
  border-radius: 8px;
  display: grid;
  justify-content: center;
  padding: 5px;
  top: 19%;
}

 .menuTab .subtab{
  float: right;
  height: 20px;
  background: var(--color_primary);
  border-radius: 1.2vw;
  width: 100%;
  height: 2vw;
  align-items: center;
  display: flex;
  font-size: 1vw;
  justify-content: center;
  line-height: 15px;
  padding: 5px;
  /* text-align: center; */
  cursor: pointer;
}

    

.msgreceived-main {
  position: absolute;
  right: 0;
  float: right;
  /*height: 100%;*/
  width: 260px;
  z-index: 10;
  top: 0;
}

.msgreceived-main .msgreceived .msgdelete {
  background: var(--color_white);
  padding: 5px;
  border-radius: 50%;
  color: var(--color_primary);
  display: flex;
  align-items: center;
  line-height: 8px;
  font-weight: bold;
  justify-content: center;
  float: right;
  position: absolute;
  text-align: right;
  font-size: 13px;
  cursor: pointer;
  z-index: 10;
  right: 15px;
}

.msgreceived-main .msgreceived {
  height: 65px;
  width: 250px;
  background: var(--color_primary);
  z-index: 99;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid;
  padding: 5px;
  box-shadow: 0px 5px 8px 0px var(--color_primary);
}

.msgreceived-main .msgreceived .msgreceived-title {
  float: left;
  text-align: center;
  cursor: pointer;
  height: 55px;
  position: relative;
  margin: 0 auto;
  width: 92%;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 26px;
  font-family: var(--font-family-Futura-Md-BT);
}

.msgreceived-main .msgreceived .msgreceived-detail {
  float: left;
  width: 97%;
  height: 31px;
  text-align: left;
  margin-left: 10px;
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentVideoSmallMultiWindow{
    position: relative;
    float: right;
    border-radius: 6px;
    z-index: 9;
    right: 2%;
    width: 213px;
    height: 131px;
    background: var(--color_white);
    border: 2px solid var(--color_black);
    top: 0.4rem;
    margin-bottom: 5px;
}

.contentVideoSmall {
  position: absolute;
  float: inherit;
  /* height: 20% !important; */
  /* transition: all .5s linear; */
  /* background: var(--color_white); */
  border-radius: 1vw;
  z-index: 9;
  left: 0%;
  width: 15vw;
  height: 10.5vw;
  background: var(--color_white);
  border: 2px solid var(--color_black);
  /* opacity: 0.5; */
  top: 0%;
}

.msgcounter {
  margin: -11px 0 0px 26px;
  float: left;
  position: absolute;
  height: 20px;
  width: auto;
  background: #de1010;
  z-index: 999 !important;
  padding: 6px;
  font-size: 13px;
  border-radius: 50%;
  line-height: 8px;
  font-weight: bold;
}

.videoloading {
  text-align: center;
  position: absolute;
  float: left;
  top: 30%;
  left: 36%;
}

.video-title {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 2%;
  margin: auto;
  width: 320px;
  position: absolute;
  top: 32%;
}

.classroom {
  display: flex;
  flex-direction: row;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
}
.classroomSmallScreen{
  display: flex;
  flex-direction: row;
  height: 100%;
}
.tabsection {
  /* color: #555;
  position: absolute;
  width: 100%;
  z-index: 0; */
  color: #555;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.tabtitleDIV {
  /*padding: 0.5rem;*/
  /*margin-top: 35px;*/
  /*margin-left: 15px;*/
}

.tabtitle {
  /* float: left;
  width: 100%;
  margin-left: 15px; */
  float: left;
  height: 10%;
  width: 100%;
  border-bottom: 2px solid #E4E9F0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.tabtitleSmallScreen{
  float: left;
  height: 10%;
  width: 100%;
  border-bottom: 2px solid #E4E9F0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.tabtitle .titlename {
  margin:0;
}
.tabtitleSmallScreen .titlename{
  margin: 0;
}
.tabtitleSmallScreen h3.titlename span{
  font-size: 1em;
  margin-left: .5rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.2rem;
}
.tabtitle h3.titlename span{
  font-size: 1em;
  margin-left: .5rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.2rem;
}

/* .copylink {
  float: right;
  width: 15%;
  margin-top: 4px;
  background: url('../public/icons/invite.png');
  background-repeat: no-repeat;
} */

.odvmain {
  display: block;
  width: 100%;
  position: relative;
  height: 65%;
  overflow-y: scroll;
  overflow-x: auto;
  top: 17vw;

}

.right-side-section {
  /* position: relative;
  z-index: 9;
  left: 3%;
  top: 1%; */
  position: relative;
    z-index: 9;
    /* margin: 38px 0 0 70px; */
    padding: 0 3% 0 0%;
    /* top: 1%; */
    width: 100%;
    height: 100%;
}
.right-side-section-tileView{
  position: relative;
  z-index: 9;
  /* margin: 38px 0 0 70px; */
  /* padding: 0 0 0 3%; */
  /* top: 1%; */
  width: 100%;
}
.odvmain .videolist {
  float: left;
  width: -webkit-fill-available;
  background: var(--color_white) solid;
  overflow: hidden;
  transition: none;
  border-radius: .9rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  line-height: 1.8rem;
  background: var(--color_white);
  margin: .5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  
}

.odvmain .videolist .thumbnail {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
   /* background: var(--color_white); */
}

.odvmain .videolist .thumbnail img {
  width: 8vw;
  height: 6vw;
  border-radius: 5px;
  box-shadow: 5px 5px 5px -1px #e1dede;
  cursor: pointer;
}

.odvmain .videolist .title {
  float: left;
  width: 65%;
  font-size: .9vmax;
  overflow: hidden;
  cursor: pointer;
  margin-left: .5rem;
  font-family: var(--font-family-Futura-Bk-BT);
  color: #9BABC5;
  line-height: 1.1em;
  font-style: normal;
  text-align: left;
  vertical-align: Top;

}

.odvmain .videolist .title .titlename {
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
  height: 1.4rem;
  font-size: .9em;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.1vmax;
  vertical-align: top;
  color: #434358;
  text-align: left;
  line-height: 24px;
  margin-bottom: .5vmax;
}

.copied {
  color: green;
  float: left;
  margin: -5px 0px 0px -72px;
}
.tabtitleSmallScreen h3{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  width: 60%;
  line-height: 36px;
  /* text-align: center; */
  color: var(--color_primary);
}
.tabtitle h3 {
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vmax;
  width: 70%;
  line-height: 1.7vmax;
  text-align: left;
  color: var(--color_primary);
}

.tabtitle h3 a {
  float: right;
  /* margin-right: 30px; */
  cursor: pointer;
}
.componentDiv{
  display: flex;
  width: 100%;
  height: 100%;
}
.videoright::after {
  position: absolute;
  z-index: 999;
  height: auto;
  width: auto;
  top: 30%;
  right: 2%;
}

.my-video-dimensions {
  height: 302px !important;
  width: 180px !important;
}

.my-video-dimensions::after {
  height: 302px !important;
  width: 180px !important;
}

.videoright {
    position: absolute;
    z-index: 10;
    height: auto;
    width: auto;
    top: 11%;
    right: -1%;
}
.vjs-big-play-button{
  width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
}
.video-js::after {
  right: 30px !important;
  position: absolute !important;
  z-index: 9999 !important;
  top: 49% !important;
}

.video-js {
  right: 30px !important;
  position: absolute !important;
  z-index: 9999 !important;
  top: 49% !important;
}

.no-video {
  background: #ccc !important;
  font-size: 13px;
  height: 100%;
  width: 100%;
}

.no-video h3 {
  margin: 0 auto;
  text-align: center;
}

.livestream-list {
  display: flex;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  justify-content: center;
  font-family: var(--font-family-Futura-Md-BT);
}

.livestream-list .list {
  text-align: center;
}

.livestream-list .list h3 {
  text-align: center;
}

.classroom::after {
  content: '';
  width: 160px;
  height: 35px;
  /* background:url("https://roomzzconnect.s3.amazonaws.com/logo.png"); */
  /* opacity: 0.2; */
  /*top: 30px;
  left: 20px  ;*/
  top: 32px;
  left: 35px;
  position: absolute;
  /*background: url('../public/logo-white.png');*/
  /*background-repeat: no-repeat;*/
  z-index: 1;
}

.minmaxClass {
  height: 7% !important;
  top: 2px !important;
  left: 80.8% !important;
  position: absolute !important;
  /*-moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;*/
}

.minmaxClassLeft {
  height: 7% !important;
  top: 2px !important;
  left: 48.6% !important;
  position: absolute !important;
}

.marginRight {
  /*margin-left: 86px !important;*/
}

.marginRight1 {
  /*margin-left: 215px !important;*/
}

.classroom.isModeTransitioning::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color_mine_shaft_light);
  z-index: 10;
}

.left {
  flex: 1 1 auto;
  display: flex;
  position: absolute;
  width: 85%;
  top: 7%;
  left: 10%;
  flex-direction: column;
  height: 85%;
}
.leftFullScareen{
  flex: 1 1 auto;
  display: flex;
  position: absolute;
  width: 85%;
  top: 7%;
  left: 7%;
  flex-direction: column;
  height: 85%;
}
.leftIsRightbarEnable{
  flex: 1 1 auto;
  display: flex;
  position: absolute;
  width: 70%;
  top: 7%;
  left: 7%;
  height: 85%;
  flex-direction: column;
}
.leftFullScreenIsRightbarEnable{
  flex: 1 1 auto;
  display: flex;
  position: absolute;
  width: 70%;
  top: 7%;
  left: 5%;
  height: 85%;
  flex-direction: column;
}
.screenShareLeft{
  display: none;
}
.fullScreenModeLeft{
  flex: 1 1 auto;
  display: flex;
  left: 1%;
  top: 1%;
  position: absolute;
  width: 98%;
  flex-direction: column;
  height: 98%;
}
.screenShareRightBarEnabled{
  flex: 1 1 auto;
  display: flex;
  position: absolute;
  width: 66%;
  left: 10%;
  top: 1%;
  flex-direction: column;
  height: 98%;
}
.fullscreenShareRightBarEnabled{
  flex: 1 1 auto;
  display: flex;
  position: absolute;
  width: 72%;
  left: 1%;
  top: 1%;
  flex-direction: column;
  height: 98%;
}
.contentVideoWrapperSC {
  width: 100%;
  height: 100%;
  display: block; 
  position: relative;
    margin-top: 45px;
}

.contentVideoWrapper {
  /*display: none;
  flex: 1 1 auto;
  overflow-y: hidden;*/

  /*background: orange;*/
  width: 100%;
  height: 100%;
  /*overflow: scroll;*/
  /*margin: 1.5%;*/
  /*z-index: 1;*/
}

.classroom.isContentShareEnabled .contentVideoWrapper {
  display: block;
}

.classroom.screenShareMode .contentVideoWrapper {
  display: none !important;
}

.remoteVideoGroupWrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.classroom.roomMode.isContentShareEnabled .remoteVideoGroupWrapper {
  flex: 0 0 auto;
}

.classroom.nologo {
  background-image: none !important;
  top: 0px !important;
  left: 0px !important;
}

.nologo::after {
  background-image: none !important;
  top: 0px !important;
  left: 0px !important;
  height: 0;
}
.bottomNavabr{
  position: absolute;
  width: 45%;
  height: 15%;
  left: 27%;
  bottom: 0;
  transition: all 1s ease-out;
  z-index: 10 !important;
  /* background-color: chartreuse; */
}
.localVideoWrapper {
    /* position: absolute;
    width: 97%;
    left: 20px;
    bottom: 15px;
    z-index: 10 !important;
    -webkit-app-region: no-drag; */
    /* display: none; */
    opacity: 0;
    transition: all 2s ease-out;
  }
  .screenShareViewLocalVideoWrapper{
    opacity: 1 !important;
  }
.bottomNavabr:hover  .localVideoWrapper {
  /* display: block; */
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  transition: all 2s ease-out;
  height: 100%;
  /* left: 27%; */
  bottom: 0;
  z-index: 11 !important;
}

.localVideoWrapper-resize {
  /* width: 69vw !important; */
    /* width: -webkit-calc((100% - 22em) + 3px) !important; */
    
}

/* @media (min-height: 900px) {  

  .newheader .rightside {
    width: 13%;
} 

.uploadMessages{
    max-height: calc(42vh) !important;
}

  .localVideoWrapper-resize  {
      width: 69vw !important;
  }

  .odvmain {
      margin-top: 103%;
      height: 470px;

  }
  .video-title {
      margin-top: 74%;
  }

  .file-upload-input {
      height: 36% !important;
  }

  .right-side-section {
      margin: 46px 0 0 26px;
  }
  .videoright { 
    top: 214px;
  }
} */

.localVideo {
  position: absolute;
  right: 0.25rem;
}

.classroom.screenShareMode .localVideo {
  right: auto;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.classroom.screenShareMode .controls {
  z-index: 1;
}

.right {
  display: flex;
  flex-direction: column;
  /* flex: 0 0 var(--right_panel_width); */
  /* background: var(--color_rightPanel); */
  /* height: calc(95vh); */
  height: 100%;
  /* width: 100%; */
  width: 0%;
  background: var(--color_white);
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9;
  /* border-radius: 8px; */
  /* margin: 3.6% 0 0 0; */
  /* transition: all 1s linear; */
  -webkit-app-region: no-drag;
}
.isRightBarSectionEnabled{
  width: 35% !important;
}
.fullScreenRightBarEnabled{
  width: 25% !important;
}
.fullScreenModeRight{
  display: flex;
  flex-direction: column;
  /* flex: 0 0 var(--right_panel_width); */
  /* background: var(--color_rightPanel); */
  /* height: calc(95vh); */
  height: 100%;
  /* width: 100%; */
  width: 8%;
  right: 0;
  background: var(--color_white);
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 11;
  /* border-radius: 8px; */
  /* margin: 3.6% 0 0 0; */
  /* transition: all 1s linear; */
  -webkit-app-region: no-drag;
  opacity: 0;
  transition: all 1s ease-out;
}
.fullScreenModeRight:hover{
  opacity: 1;
  transition: all 1s ease-out;
}
.rightSmallScreen{
  display: flex;
  flex-direction: column;
  background: var(--color_rightPanel);
  height: 100%;
  width: 100%;
  background: #F6F9FB;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9;
  -webkit-app-region: no-drag;
}
.classroom.screenShareMode .right {
  display: none;
}


.mainDiv {
  /* float: left; */
  width: 100%;
  height: 100%;
  /* margin: 1%; */
  display: flex;
  border-radius: 10px;
  /* background-color: #E4E9F0; */
  flex-direction: column;
  /* display: contents; */
}
.fullScreenModeMainDiv{
  width: 100%;
  height: 100%;
  /* margin: 1%; */
  display: flex;
  border-radius: 10px;
  /* background-color: #E4E9F0; */
  flex-direction: row;
}
.mainnDivBgColor{
  background: none;
}
.mainDivSmallScreen{
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--color_white);
  flex-direction: row;
}
.titleWrapper {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--color_mine_shaft_light);
}

.title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.label {
  font-size: 0.8rem;
  color: var(--color_white);
}

.deviceSwitcher {
  flex: 0 1 auto;
}

.broadcast {
  flex: 0 1 auto;
}

.roster {
  flex: 1 1 auto;
  /* overflow-y: scroll; */
  height: 50%;
  position: absolute;
  width: 320px;
  height: 100px;
  top: 82px;
  color: var(--color_black);
}

.modal {
  outline: none;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
  -webkit-app-region: no-drag;
}

.settingButton {
  display: flex;
  align-items: flex-start;
}
.settingButton button {
  background: none;
  border: none;
  outline: none;
  color: var(--color_white);
  font-size: 22px;
}

.tabs {
  /* position: relative;
  width: 320px;
  z-index: 9;
  background: var(--color_white);
  box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.1); */
  position: relative;
  width: 100%;
  height: 20%;
  z-index: 9;
}
.hw {
  height: 43px;
  width: 43px;
}
.tabsTitle {
  display: flex;
    flex-direction: row;
    width: 100%;
    /*background: orange;*/
    border-bottom: 2px solid #ccc;
    height: 50px;
}

.tabsTitle .tab {
   padding: 5px;

}
.tabs-heading {
  /* width: 100%;
  float: left;
  color: #000;
  -webkit-app-region: drag; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.tabs-heading .meeting-id {
  float: left;
  line-height: 35px;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
}

 .close-settings-button {
  border: 1px solid blue;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

 .close-settings-button img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.tabsTitle .tab img {
  height: 43px;
  width: 43px;
  cursor: pointer;
}

.cols {
     float: left;
     padding: 0px 0px 5px 10px;
     text-align: center;
     color: var(--color_black);
     font-family: var(--font-family-Futura-Bk-BT);
     font-style: normal;
     font-weight: normal;
     font-size: 1.5vw;
     line-height: 24px;
     text-align: center;
     color: #444444;
}
.cols span {
  display: block;
}
.cols .uploadBut {
  margin: 45px 0;
  background: var(--color_mine_shaft_light);
  border: 0;
  color: var(--color_white);
  padding: 10px 30px;
  font-size: 20px;
  border-radius: 5px;
}
.progress-bar {
  width: 200px;
  position: relative;
  height: 8px;
  margin-top: 4px;
}
.progress-bar .progress {
  height: 8px;
  background-color: var(--color_mine_shaft_light);
  width: 0;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 41%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin: 10px;
  /* margin-top: 21%; */
  font-family: var(--font-family-Futura-Md-BT);
  background: var(--color_white);
  box-shadow: 0px 5px 15px 5px #e8e5e5;
  border-radius: 2vw;

}

.backgroundImg {
  /*background: url('') !important;*/
}

/*.image-dropping,
.image-upload-wrap:hover {
  border: 4px dashed var(--color_white);
}*/

.drag-text {
  text-align: center;
  padding-top: 8%;
  padding-bottom: 6%;
  height: 300px;
}

.drag-text i {
  /*color: var(--color_white);*/
  font-size: 30px;
}

.drag-text h2 {
  font-weight: 100;
  text-transform: uppercase;
  /*color: var(--color_white);*/
}

.drag-box .first-div {
  float: left;
  width: 100%;
  position: relative;
}

.drag-box .first-div .first-span {
  width: 100%;
  padding: 2%;
  font-weight: bold;
  font-size: 21px;
  color: #74809d;
}

.file-status {
  font-size: larger;
  margin-top: 5px;
  color: var(--color_black);
}

.drag-box .first-div .second-span {
  width: 100%;
  padding: 2%;
  font-weight: normal;
  font-size: 18px;
  color: #c7ccd9;
}

.drag-box .img-div {
  padding: 4%;
}


.drag-box .img-div img{
      height: 45px;
}

.drag-box .dashed-div{
      /* float: left; */
    border: 3px dashed #ccc !important;
    padding: 4%; 
    border-radius: 15px;

    width: 96%;
    margin: 0 auto;
}

.drag-box .dashed-div .content {
  font-size: 15px;
    margin: 0%;
    padding: 2.5%;
}

.drag-text i {
  /*color: var(--color_white);*/
  font-size: 30px;
}

.drag-text h2 {
  font-weight: 100;
  text-transform: uppercase;
  /*color: var(--color_white);*/
}

.listName {
  padding-top: 20px;
}
.popupBox {
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}
.popupImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.popupClose {
  float: right;
  padding: 10px;
  cursor: pointer;
}

.popupFile {
  display: block;
  margin: 30px;
  width: '800px !important';
  height: '520px !important';
}

.settingRightButton {
  position: static;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;

  /*background: url('../public/icons/settings-icon.png');*/

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 23px;
}
/* 
.livebroadcast {
  position: static;
  width: 20px;
  height: 20px;
  left: 132px;
  top: 0px;

  background: url('../public/icons/broadcast-live.png');

  flex: none;
  order: 4;
  align-self: center;
  margin: 0px 23px;
}

.vod {
  position: static;
  width: 20px;
  height: 20px;
  left: 132px;
  top: 0px;

  background: url('../public/icons/vod.png');


  flex: none;
  order: 4;
  align-self: center;
  margin: 0px 23px;
}
.chatBox {
  position: static;
  width: 20px;
  height: 20px;
  left: 66px;
  top: 0px;
  flex: none;
  order: 1;
  align-self: center;
  margin: 0px 23px;
  background: url('../public/icons/vector.png');
}

.fileBox {
  position: static;
  width: 20px;
  height: 20px;
  left: 132px;
  top: 0px;

  background: url('../public/icons/icons-add-file.png');

  flex: none;
  order: 2;
  align-self: center;
  margin: 0px 23px;
} */

.meetingTitle {
  position: absolute;
  /* width: 153px; */
  height: 7.33px;
  top: 45px;

  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  padding: 0px 20px;

  /* Black/100% */

  color: var(--color_black);
}

.chat {
  /* position: relative;
  width: 240px;
  height: 560px; */

  /* White/100% */
}

.chatTitleBox {
  position: absolute;
  width: 240px;
  height: 72px;
  top: 50px;
}

.chatTitle {
  position: absolute;
  width: 39px;
  height: 16px;

	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  padding: 0px 20px;

  /* Black/100% */

  color: var(--color_black);
}

.chatParticipants {
  position: absolute;
  width: 208px;
  height: 16px;
  top: 40px;
}

/* .chatIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 270px;
  background: url('../public/icons/more-elipses.png');
} */
.backIcon {
  position: relative;
  background-color: var(--color_white);
  width: 4.5vw;
  height: 4.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vw;
  
}

/* .chatParticipantsIcon {
  position: absolute;
  width: 8px;
  height: 5px;
  margin-left: 295px;
  background: url('../public/icons/more-participants.png');
} */

.fileSharedTitle {
  position: absolute;
  /* width: 153px;
  height: 7.33px; */
  top: 55px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--color_black);
  padding-left: 12px;
}


.uploadMessages {
  margin-top: 3%;
  overflow-y: scroll;
  overflow-x: auto;
  padding: 1%;
  height: 60%;
  display: flex !important;
  flex-direction: column;
}


.uploadedFiles {
  padding: 0px;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  height: 20%;
  margin-top: 3%;
  border-radius: 1.2vw;
  background: #F6F9FB;
  box-shadow: 2px 2px 20px 2px rgba(246, 249, 251, 0.06);
}

.participants {
  position: relative;
  width: 176px;
  height: 97%;
  top: 2px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 8px;
  margin: 1%;
  z-index: 9;
}
.chatOpenIcon{
  width: 4.5vw;
height: 4.5vw;
display: flex;
justify-content: center;
align-items: center;
background: var(--color_primary);
border-radius: 1.5vw;
}
.chatOpenIcon img{
  object-fit: contain;
  height: 2.2vw;
  width: 2.2vw;
}
.inactive{
  display: none;
}
.TitleHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  width: 4vw;
  height: 4vw;
  background: var(--color_white);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 1.5vw;
}
.TitleHeader img{
  object-fit: contain;
  width: 1.5vw;
  height: 1.5vw;
  transform: rotate(180deg);
  transition: all 1s ease-out;
}
.alertMessage{
  position: absolute;
  left: 35%;
  height: 20%;
  top: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.poorConnectionMessage{
  position: relative;
  width: 30vw;
  height: 4.5vw;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  line-height: 2vw;
  color: #434358;
  padding: 6%;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0 1.2vw;
}
.micMuteMessage{
  position: relative;
  width: 30vw;
  height: 4.5vw;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  line-height: 2vw;
  color: #434358;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0 1.2vw;
}
.micMuteMessage div{
  width: 80%;
}
.micMuteMessage img{
  object-fit: contain;
  width: 2vw;
  height: 2vw;
  margin-right: 10px;
}
.messageCounter{
  font-size: 12px;
  position: absolute;
  background-color: red;
  color: var(--color_white);
  font-family: var(--font-family-Futura-Md-BT);
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin-top: -22px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeNotification{
  position: absolute;
    right: -3px;
    top: -5px;
    width: 20px;
    height: 20px;
    color: var(--color_white);
    background: #aeaeae;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 10px;
    cursor: pointer;
}