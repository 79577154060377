/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');*/

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbb;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb;
}

.createOrJoinParentDiv{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.createOrJoinDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.openChat{
  width: 35% !important;
}
.chatComponent{
  display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 2%; */
    height: 100%;
    border-left: 2px solid #9babc5;
    width: 10%;
}
.tabtitle {
  /* float: left;
  width: 100%;
  margin-left: 15px; */
  float: left;
  height: 10%;
  width: 100%;
  border-bottom: 2px solid #9babc5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.tabtitleSmallScreen{
  float: left;
  height: 10%;
  width: 100%;
  border-bottom: 2px solid #9babc5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.tabtitle .titlename {
  margin:0;
}
.tabtitleSmallScreen .titlename{
  margin: 0;
}
.tabtitleSmallScreen h3.titlename span{
  font-size: 1em;
  margin-left: .5rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.2rem;
}
.tabtitle h3.titlename span{
  font-size: 1em;
  margin-left: .5rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.2rem;
}
.tabtitleSmallScreen h3{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  width: 70%;
  line-height: 36px;
  /* text-align: center; */
  color: var(--color_primary);
}
.tabtitle h3 {
  font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 1.7vmax;
    width: 70%;
    line-height: 1.7vmax;
    text-align: left;
    color: var(--color_primary);
  }

.tabtitle h3 a {
  float: right;
  /* margin-right: 30px; */
  cursor: pointer;
}
.chatIconHeader{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  margin-top: 15%;
  width: 5vw;
  height: 5vw;
  background: var(--color_primary);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 1.5vw;
}
.chatIconHeader img{
  object-fit: contain;
    width: 2.5vw;
    height: 2.5vw;
}
.TitleHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  width: 4vw;
  height: 4vw;
  background: var(--color_white);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 1.5vw;
}
.TitleHeader img{
  object-fit: contain;
  width: 1.5vw;
  height: 1.5vw;
}
.createOrJoin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  /* margin-bottom: 20px; */
  /* height: 40px; */
  /* width: 160px;
  height: 33px; */
}

.versionNumber {
  color: #9babc5;
  /* margin-top: -20px; */
  font-family: var(--font-family-Futura-Md-BT);
  font-size: smaller;
  margin-bottom: 10px;
}
.descriptionMessage{
  display: flex;
  flex-direction: column;
}
.descriptionMessage h3{

  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: var(--color_primary);
  margin: 5px 0px 0 0px;
}
.descriptionMessage span{
  font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #9babc5;
    margin: 10px 0 10px 0;
}

.formWrapper {
  position: relative;
  /* width: 355px; */
  width: auto;
  height: auto;
  /* border-radius: 12px; */
  /* background: var(--color_white); */
  /* border-radius: 12px; */
  /* color: var(--color_white); */
  /* padding: 2%; */
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.title {
  font-size: 1.2rem;
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  align-items: center;
  text-align: center;
  margin:0;
  color: var(--color_primary);
}

.form {
  /* width: 100%;
  padding: 30px;
  margin-top: -20px;
  left: initial;
  margin-left: 40px; */
}

.form form {
  /* float: left;
  margin-left: -20px; */
}
.formLabel {
	display: flex;
	font-family: var(--font-family-Futura-Md-BT);
	font-style: normal;
	font-weight: normal;
	font-size: 1.1rem;
	line-height: 24px;
	color: var(--color_primary);
	margin: .5rem;
  width: 350px;
  padding-left: 0.3rem;
}
.titleInput,
.nameInput {
  /* display: block; */
  width: 350px;
    height: 55px;
  background: var(--color_white);
  border-radius: 20px;
  /* margin: 0.5rem 0 0 0; */
  padding: 0.75rem 1rem;
  /* font-size: 1rem; */
  border: none;
  box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
    line-height: 24px;
  display: flex;
  /* align-items: center;
  text-align: center; */
  color: #9babc5;
  /* border-top: none; */
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 1.2rem; */
 
}

.titleInput:focus,
.nameInput:focus {
  color: #9babc5;
}
.button {
  border: none;
  width: 350px;
  height: 55px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background: var(--color_primary);
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: var(--color_white);
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.2rem;
  margin-top: 1.2em ;
  /* padding: 0; */
  padding-left: 110px;
  outline: 0;
}
.button img{
  object-fit: contain;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.submitbutton{
  border: none;
  width: 350px;
  height: 55px;
  background: var(--color_primary);
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: var(--color_white);
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.2rem;
  padding: 0;
}
.button:hover {
  filter: brightness(85%);
}

.loginButton {
  border: none;
  width: 350px;
  height: 55px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: var(--color_white);
  align-items: center;
  text-align: center;
  margin-left: auto;
    margin-right: auto;
  /* padding: 0; */
  padding-left: 110px;
  outline: 0;
}
.loginButton img{
  object-fit: contain;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.margintop {
  /* margin-top: 35px; */
}

.logoutButton {
  border-radius: 50% !important;
  width: 43px;
  height: 42px;
  cursor: pointer;
  transition: background-color 0.15s;
  color: var(--color_white);
  align-items: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 26px;
  line-height: 42px;
}

.loginButtonColor {
  background: var(--color_secondary);
}

.logoutButtonColor {
  background: #fd5f03;
}

.logoutButtonColor:hover {
  background: #f06a1c !important;
}

.loginButton:hover {
  filter: brightness(85%);
}
.loginLink_url {
  display: inline-block;
  color: var(--color_primary);
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
}
.loginLink {
  display: inline-block;
  color: var(--color_white);
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
}

.logoutOptions {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin: 1.4rem;
  background: var(--color_secondary);
  width: 95px;
  font-family: var(--font-family-Futura-Md-BT);
  text-align: center;
  padding: 11px;
  border-radius: 12px;
}

.loginLinkBlackColor {
  color: var(--color_black) !important;
  text-decoration: none !important;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.loginLink:hover {
  text-decoration: underline;
}

.leftSide {
  float: left;
  color: var(--color_black);
}

.rightSide {
  float: right;
  color: var(--color_primary) !important;
  font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 24px;
   
}

.bgcolorRightSide {
  float: right;
  background: var(--color_primary) !important;
}

.bgcolorLeftSide {
  float: left;
  /* background: #1eafa8 !important; */
}
.login-bottom-link {
  /* margin: -28px 0px 0px 0px; */
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  /* margin-bottom: 20px; */
}

.login-bottom-link .logout {
  float: left;
  margin-top: 15px;
  margin-left: 10px;
}

.login-bottom-link a {
  cursor: pointer;
  transition: opacity 0.15s;
  text-decoration: none;
  font-style: normal;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--color_primary);
}

.login-bottom-link a:hover {
  text-decoration: none;
}

input {
  outline: 0;
}

.deviceSettingContainer {
  display: block;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.videoPreview {
  /* width: 393px !important; */
  /* height: 193px !important; */
  margin: 0vw 0 0.5vw;
  min-height: 13.5vw;
  border-radius: 15px !important;
}
.regionsList {
  margin-bottom: 0.75rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  border: 1px solid var(--color_alto);
  display: none;
}
.dropdown {
  margin-bottom: .75vw;
  width: 100%;
}

.form_label {
	font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 3vh;
  display: flex;
  align-items: center;
  color: var(--color_primary);

}

.deviceList {
  padding: 1rem;
  /*margin-top: -2% !important;*/
  /*float: left;*/
  width: 100%;
}

.device-list {
  width: 100%;
  margin-top: 15px;
}

.device-list .device-name {
  float: left;
  width: 100%;
  height: 24px;
  font-weight: bold;
  margin: .5rem;
  font-size: 15px;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  /* font-size: 20px; */
  line-height: 24px;
  color: var(--color_primary);

}

.control {
  cursor: pointer;
  outline: none !important;
  box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
  transition: none;
  border-radius: .9rem;
  padding: 0.5rem;
  border: none;
  height: auto;
  line-height: 1.7rem;
  margin-top: 10px;
  /* width: 100%; */
  display: block;
  background: var(--color_white);
  margin: 0;
  padding: 0.5rem;
  /* width: 350px; */
  font-size: 1rem;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  align-items: center;
  /* text-align: center; */
  border-top: none;
  color: #9BABC5;
  height: 3.2vw;
}

.placeholder {
  /*color: var(--color_alto1);*/
  color: #9BABC5;
  width: 93%;
}

.arrow {
  border-color: var(--color_alto) transparent transparent;
  border-width: 0.3rem 0.3rem 0;
  margin-top: 7px;
  margin-right: 0.25rem;
}

.dropdown[class~='is-open'] .arrow {
  border-color: var(--color_alto) transparent transparent !important;
  border-width: 0.3rem 0.3rem 0 !important;
}

.menu {
  margin: 0;
  padding: 0.5rem;
  color: var(--color_alto);
  /*background-color: var(--color_cod_gray_medium);*/
  box-shadow: 0 0.25rem 0.5rem var(--color_black_low_opacity);
  overflow: hidden;
  font-size: 0.9rem;
  border: none;
  max-height: none;
  border-radius: .8rem;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
}

.menu *[class~='Dropdown-option'] {
  /*color: var(--color_silver_chalice);*/
  color: var(--color_alto);
  /* border-radius: 1.2rem; */
}

.menu *[class~='Dropdown-option']:hover {
  background-color: #c3c0c0;
  color: var(--color_white);
}

.menu *[class~='is-selected'] {
  background-color: transparent;
  /*color: var(--color_alabaster);*/
  color: var(--color_argent);
}

.menu *[class~='is-selected']:hover {
  background-color: #c3c0c0;
  color: var(--color_white);
}
.errorMessage {
  color: var(--color_thunderbird);
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    /* padding: 5px 5px 10px 5px; */
    font-size: 0.8rem;
    /* width: 215px; */
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
}
.successMessage {
  /*color: var(--color_mercury);*/
  color: var(--color_green);

  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 10px 5px;
  font-size: 0.8rem;
}

.loginLinkBottom {
  margin: 21rem 18rem 0px 0px;
}

.loginPageLink {
  margin: 21rem 5rem 0px 0px;
}

.joinPageLink {
  margin: 21rem 4rem 0px 0px;
}

.registerLinkBottom {
  margin: 23.5rem 19rem 0px 0px;
}
.meetingsCard{
  margin-top: 20px;
  width: 173px;
  height: 62px;
  border-radius: 5px;
  background: var(--color_white);
  color: var(--color_white);
  padding: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  -webkit-app-region: no-drag !important;
}
.meetingTitle{
    color: #2d99e5;
  margin-top: 5px;
  font-size: small;
}
.meetingDate{
  color: #72ad3e;
  margin-top: 5px;
  font-size: 10px;
  float: left;
  margin-left: 10px;
}
.meetingTime{
  color: #72ad3e;
  margin-top: 5px;
  font-size: 10px;
  float: right;
  margin-right: 10px;
}
.modal {
  outline: none;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
  -webkit-app-region: no-drag;
}
.joinMeetingLink{
  display: flex;
  align-items: center;
  justify-content: center;
}
.joinWithDesktop{
  background: var(--color_white);
  box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
  border-radius: 1.2vw;
  margin: 1.2vw 0 1.2vw 0px;
}
.continueWithParentClass{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  /* justify-content: space-around; */
  padding: 1.2vw;
}
.continueWithChild1Class{

}
.continueWithChild2Class{
  margin-left: 1.2vw;
}
.text1{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  text-align: left;
  color: #434358;
}
.text2{
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  color: #9babc5;
}
.text3{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  text-align: left;
  color: var(--color_primary);
}
.joinWithWeb{
  background: var(--color_white);
  box-shadow: 2px 2px 20px 2px rgb(0 0 0 / 6%);
  border-radius: 1.2vw;
  margin: 1.2vw 0 1.2vw 0px;
}
.continueInIcon{
  object-fit: contain;
  width: 4vw;
  height: 4vw;
}
.showPassword{
  position: absolute;
  top: 50px;
  width: 20px;
  right: 50px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}
.totalMessageCount{
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  color: var(--color_white);
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -5px;
}