/* .button {
    width: 100%;
  } */
  
  video {
    max-width: 100%;
  }
  
  /* .list {
    padding: 0 1.2vw;
    margin: 2vw 0 2.5vw;
  }
 
  .list li {
    margin-bottom: 1vw;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    color: #9BABC5;
    font-size: 2.1vh;
    line-height: 3vh;
    align-items: center;
  } */

  .instruction{
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
  font-weight: normal;
  font-size: 2.1vh;
  line-height: 3vh;
  display: flex;
  align-items: center;
  color: #ABABAB;
  }

  .joinMeeting{
    /* font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 5px;
    color: white;
    background-color: #E03D3C; */
    background: linear-gradient(90deg, #E86F00 0%, #BA0155 100%);
    /* border-radius: 10px !important;
    width: 115px; */
    padding: 0.75vw !important;
    font-size: 2.2vh !important;
    line-height: 2.2vh;
    font-weight: bold !important;
  }

  .readyButton{
    background: linear-gradient(90deg, #E86F00 0%, #BA0155 100%);
    padding: 0.75vw !important;
    font-size: 2.2vh !important;
    line-height: 2.2vh;
    font-weight: bold !important;
  }

  .dressingRoom_wrapper{
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
  }
  .dressingRoom{
    position: relative;
    /* top: 55%; */
    /* left: 50%; */
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    padding: 0vw 3vw;
    /* transform: translate(-50%, -50%); */
    /* background-color: #242424 !important; */
    border-radius: 0.125rem;
    overflow: hidden;
  
  }
  .dressingRoom h1 {
    text-align: center;
    /* margin: 0 0 2vw 0; */
    margin: 0;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 4vh;
    line-height: 3vw;
    /* display: flex; */
    /* align-items: center; */
    color: var(--color_primary);
  }
  .dressingRoom p{
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #9BABC5;
  }

  .spinnerPara{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .joinButton{
    padding: 0.75vw !important;
    font-size: 2.2vh !important;
    line-height: 2.2vh;
    font-weight: bold !important;
    border: none;
    border-radius: 1.5vw;
    background: var(--color_primary);
    color: white;
    font-family: var(--font-family-Futura-Md-BT);
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    width: 70%;
  }
.settingDescription{
  font-family: var(--font-family-Futura-Bk-BT);
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 24px;
/* identical to box height */


color: #9BABC5;
}
.skipSettings{
  text-align: center;
    margin: 0 1vw 0;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    color: var(--color_primary);
    cursor: pointer;
} 