/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .rosterDiv {
  /* color: var(--color_alabaster); */
  height: 100%;
  /* height: calc(47vh - 127px); */
  display: table;
  overflow-y: auto;
  flex-direction: column;
  position: relative;
  color: black;
      width: 100%;
}

.noAttendee {
  color: var(--color_silver_chalice);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rosterAttendee {
  flex: 0 0 3rem;
  overflow: hidden;
  display: flex;  
  flex-direction: row;  
  align-items: center;
  /* height: 2.5rem; */
  /* cursor: pointer; */
  outline: none !important;
  /* box-shadow: none !important; */
  transition: none;
  border-radius: 1.2vw;
  padding: 0.5rem;
  justify-content: space-between;
  /* height: 5rem; */
  line-height: 1.8rem;
  /* width: 100%; */
 	background: #ffffff;
	margin: .5rem;

	/* border: 1px solid var(--color_alto);  */
	box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
	/* font-family: var(--font-family-Futura-Md-BT);
	font-style: normal;
	align-items: center;
	text-align: center;
	border-top: none;  */
  color: var(--color_light_grey);
}
.attendeeInfo{
  display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-evenly;
}
.userProfile{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw;
  color: #fff;
  font-family: var(--font-family-Futura-Md-BT);
  font-size: 1.5vw;
  height: 3vw;
  background-color: var(--video-background-color);
  border-radius: 0.6vw;
  text-transform: capitalize;
}
.rosterscroll {
    height: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    /* height: calc(103vh - 34rem); */
    /* width: 320px; */
    width: 100%;
    /*background: aquamarine;*/
}

.rosterscroll::-webkit-scrollbar {
  width: 5px;
}
.rosterscroll::-webkit-scrollbar-thumb {
  background: #9BABC5;
  border-radius: 5px;
}
.imgIcon {
  position: relative;
  background-color: #FFFFFF;
  /* width: 25%; */
  /* height: 4.5vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5vw;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  
  
}
.imgIcon img{
  object-fit: contain;
  width: 3.5vw;
  height: 3.5vw;
}
.attdname {
  position: relative;
  width: 60%;
  max-height: 3vw;
  text-overflow:ellipsis;
    overflow: hidden;
    Font-family: var(--font-family-Futura-Md-BT);
    Font-style : Medium;
    Font-size : 1rem;
    vertical-align: Top;
    color: #9babc5;   
}
.hostName{
  position: relative;
  width: 60%;
  max-height: 3vw;
  text-overflow:ellipsis;
    overflow: hidden;
    Font-family: var(--font-family-Futura-Md-BT);
    Font-style : Medium;
    Font-size : 1rem;
    vertical-align: Top;
    color: var(--color_primary); 
}
.isHost{
  background: var(--color_primary);
}
.isActiveAttd{
  background: var(--color_secondary);
}
.activeAttdName{
  position: relative;
  width: 60%;
  max-height: 3vw;
  text-overflow:ellipsis;
    overflow: hidden;
    Font-family: var(--font-family-Futura-Md-BT);
    Font-style : Medium;
    Font-size : 1rem;
    vertical-align: Top;
    color: var(--color_secondary); 
}
.iconDiv{
  display: flex;
  width: 50%;
  justify-content: space-around;
}
.raisedHand {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

.rostervideoicon {
  position: relative;
  /* width: 10%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  /* align-items: center;
  background-color: cadetblue; */
}
.rosterhangonicon {
  position: relative;
  /* width: 10%; */
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  /* align-items: center;
  background-color: cadetblue; */
}
.rostermicicon {
  position: relative;
  /* width: 10%; */
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* background-color: burlywood; */
}
.rosterunmuteicon {
  position: relative;
  /* width: 10%; */
  display: flex;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* background-color: antiquewhite; */
}
.rosterunmuteicon img{
  object-fit: contain;
  width: 2vw;
  height: 2vw;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}