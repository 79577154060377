/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .chatInputDiv {
  /* position: absolute;
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 12px 0px; */
  width: 100%;
  height: 100%;
}

.chatForm {

  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: #F6F9FB;
  border-radius: 0.25rem;
}

.input {
  /* flex: 1 1 auto;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  width: 100%;
    float: left;
    bottom: 0px;
    position: absolute;
    border: 10px solid #ccc;
    border-radius: 10px 10px 0px 0px; */
    width: 80%;
    height: 70%;
    background: #FFFFFF;
    padding: 0.4vw 1.1vw 0.4vw 1.1vw;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
    border: none;
    border-radius: 20px;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
}

.input::placeholder {
  color: var(--color_silver_chalice);
}

.raiseHandButton {
  flex: 0 0 auto;
  font-size: 1.5rem;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin-top: 0.25rem;
  float: right;
}

.raiseHandButton span {
  transition: all 0.05s;
  filter: grayscale(100%);
}

.raiseHandButton span:hover {
  filter: grayscale(0);
}

.raiseHandButton.raised span {
  filter: grayscale(0) !important;
}

/* .attachIcon {
  position: absolute;
  width: 16px;
  height: 16px;
background: url('../public/icons/icon-attach.png');
} */
.inputSpan{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.sendMessageButtton{
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 45px;
  background: var(--color_primary);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: none !important;
}
.sendMessageButtton img{
  object-fit: contain;
  width: 25px;
  height: 25px;
}