.deviceContainer {
  display: none;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}
.timer_formWrapper{
  /*  position: absolute;
*/  width: 100%;
height: auto;
border-radius: 12px;
/* background: #ffffff; */
border-radius: 12px;
/* color: white; */
padding: 2%;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
box-shadow: 0px 0px 100px 1px #ffffff82;
-webkit-app-region: no-drag !important;
}
.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  /* margin-bottom: 20px; */
  /* height: 40px; */
  /* width: 160px;
  height: 33px; */
}

.versionNumber {
  color: #9babc5;
  /* margin-top: -20px; */
  font-size: smaller;
  margin-bottom: 10px;
}
.descriptionMessage{
  display: flex;
  flex-direction: column;
}
.descriptionMessage h3{

  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: var(--color_primary);
  margin: 5px 0px 0 0px;
}
.descriptionMessage span{
  font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #9BABC5;
    margin: 10px 0 10px 0;
}
.title {
  font-size: 1.2rem;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: normal;
  align-items: center;
  text-align: center;
  margin:0;
  color: var(--color_primary);
}
.deviceList {
  min-width: 20rem;
}

/* .videoPreview {
  width: 100%;
  margin: 1rem 0 2rem;
  min-height: 13.5rem;
} */
.videoReady{
  width: 600px;
  border-radius: 15px;
  height: 300px;
  /* margin: 1rem 0 2rem; */
  /* min-height: 13.5rem; */
  object-fit: fill;
}

.timermainDiv{
  display: flex;
  /* margin: 14% 0 0 30%; */
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.readyOrJoin{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.head{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  /* display: flex; */
  align-items: center;
  color: #9babc5;
  margin: 0;
}

.spanHead{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;

color: #ABABAB;
margin-top: 20px;
}
.joinMeeting{
  /* font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  border-radius: 5px;
  color: white;
  background-color: #E03D3C; */
  background: linear-gradient(90deg, #E86F00 0%, #BA0155 100%);
  border-radius: 10px !important;
  /* width: 115px; */
  padding: 13px 57px 13px 57px !important;

}
.btnDiv{
  top: 15%;
  /* width: 167px; */
  /* padding: 0px; */
  position: relative;
  /* left: 47%; */
  display: flex;
}
.gotoHome{
font-size: 10px;
font-family: var(--font-family-Futura-Md-BT);
font-weight: 400;
font-style: normal;
border-radius: 10px !important;
/* border: 0.125rem solid var(--color_grey_20); */
color: white;
background-color: black !important;
border: 1px solid #E03D3C !important;
width: 13vw;
margin-left: 25px;
}
.clock{
  color: white;
  background-color: var(--color_primary);
  /* width: 9px !important; */
  /* height: 2rem; */
  padding: 7px 14px 7px 14px;
  /* padding-inline-end: 23px; */
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 3px;
  border-radius: 5px
}
.dots{
  color: var(--color_primary);
  /* font-size: 27px; */
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
}
.clockMainDiv{
  margin: 10px 0 10px 0;
  width: 323px;
  height: 50px;
}
.imageDiv{
  width: 180px;
  height: 180px;
}
.img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.waitRoomJoinButton{
  padding: 0.75vw !important;
  font-size: 2.2vh !important;
  line-height: 2.2vh;
  font-weight: bold !important;
  border: none;
  border-radius: 1.5vw;
  background: var(--color_primary);
  color: white;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
  line-height: 24px;
  width: 70%;
}