/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .remoteVideo {
  display: none;
  position: relative;
  background: transparent;
  /*overflow: hidden;*/
}

.activeSpeaker {
    border: 2px solid var(--color_secondary) !important;
    border-radius: 1.2vw !important;
}
.activeSpeakerScreenShare{
  border: 2px solid var(--color_secondary) !important;
  border-radius: 20px !important;
}
.activeSpeaker1 {
  border: solid 2px var(--color_secondary);
  border-radius: 20px;
}

.largevideo {
    border:none !important;
    background: none !important;
}

.muted {
  float: left;
    text-align: left !important;
    position: absolute;
    top: 3px !important;
    left: -6px !important;
    background: #ff0000b8;
    border-radius: 4px;
    width: 23px !important;
    text-align: center !important;
}

/*.removeattendee {
    position: absolute;
    top: -6rem;
    right: 1px;
    display: flex;
    flex-direction: row;
}

.removeattendee .user{
    background: var(--color_white);
    margin-left: 5px;
    border-radius: 3px;
    padding: 2px;
    text-align: center;
    width: 26px;
}

.removeattendee .user i{
    font-size: 13px;
}*/

.participantStatus {
    position: absolute;
    /* z-index: 8;
    width: 95%;
    background: #0006;
    border-radius: 4px;
    display: table;
    margin: 100px 8px -20px 5px; */
    float: left;
    bottom: 2.5vw;
    width: 100%;
    height: 0;
    z-index: 8;
}

.participantControl{
  position: absolute;
  /* float: right; */
  /* bottom: 40px; */
  top: 0;
  /* left: 45px; */
  width: 100%;
  height: 0;
  z-index: 8;
}
.volumecontrol {
  position: relative;
  float: left;
  height: 10px;
  width: 3px;
  background: #8a8d8a;
  margin-left: 1px;
}

.mutemic {
    -ms-transform: rotate(116deg);
    transform: rotate(116deg);
    width: 3px;
    height: 27px;
    margin: -8px 0 0px -9px;
}

.nameStatus .firstdiv {
  height: 30px;
  display: flex;
  flex-direction: row;
  /* margin: 1px; */
  width: 130px;
  justify-content: center;
  align-items: center;
  line-height: 27px;
  cursor: pointer;
}
.nameStatus .firstdiv span {
    /* margin: -24px 15px 0px 10px; */
}

.firstdiv div img{
    height: 21px;
    margin: 2px 2px 0px -4px;
}
.controStatus{
    /* display: flex; */
    /* flex-direction: row; */
    float: right;
    /*position: relative;*/
    width: 6vw;
    height: 2.5vw;
    /* left: 188px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* top: 393px; */
    background: var(--color_white);
    border-radius: 0 1vw 0 1vw;
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    /* font-size: 20px;
    line-height: 24px;
    text-align: center; */
    color: #434358;
}
.controStatusJoinee{
  /* display: flex; */
/* flex-direction: row; */
float: right;
/*position: relative;*/
width: 3vw;
height: 2.5vw;
/* left: 188px; */
display: flex;
justify-content: space-evenly;
align-items: center;
/* top: 393px; */
background: var(--color_white);
border-radius: 0 1vw 0 1vw;
font-family: var(--font-family-Futura-Md-BT);
font-style: normal;
font-weight: 500;
/* font-size: 20px;
line-height: 24px;
text-align: center; */
color: #434358;
}
.screenShareViewControlStatus{
  width: 93px;
  height: 40px;
  border-radius: 0 20px 0 20px;
}
.screenShareViewControlStatus i{
  font-size: 15px !important;
}
.nameStatus {
  /* display: flex; */
    /* flex-direction: row; */
    /*float: left;*/
    /*position: relative;*/
    width: 6vw;
    height: 2.5vw;
    /* left: 188px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* top: 393px; */
    background: var(--color_white);
    border-radius: 0 1vw 0 1vw;
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.2vw;
    text-align: center;
    color: #434358;
}
.fullScreenControl{
  flex-direction: column;
  /* width: 14.5vw;
  height: 12.5vw; */
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color_white);
  border-radius: 0 1.2vw 0 1.2vw;
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 1.6vw;
  text-align: center;
  color: #434358;
  padding: 10px 15px 10px 15px;
}
.fullScreenControl .firstdiv{
  align-items: center;
  cursor: pointer;
  height: 25%;
  display: flex;
  width: 10vw;
  flex-direction: row;
  font-size: 16px;
  padding: 3px 0px 3px 0px;
}
.fullScreenControl .firstdiv span{
  margin-left: 10%;
}
.fullScreenControl .firstdiv img{
  object-fit: contain;
  width: 24px;
  height: 24px;
}
/* .newlook div {

    background: red;
    width: 50px;
    border-radius: 4px;
    margin-right: 4px;
    text-align: center;
} */
/* 
.newlook div i{
    color: var(--color_white);
    font-size: 13px;
}

.newlook div.name{

    width: 100%;
    background: inherit;
    float: right;
    text-align: left;
} */



.participantStatusNew {
  position: absolute;
  left: 2%;
  z-index: 1;
  bottom: 11vw;
  opacity: 0;
  transition: all ease-out 1s;
  width: auto;
}
.participantStatusNew:hover{
  opacity: 1 !important;
  transition: all ease-out 1s;
}
.attendee {
      z-index: 1;

}

.participantStatusWidht {
  /* width: auto !important; 
  left: -webkit-calc((100% - 31em) + 3px) !important; */
}

.participantStatusWithRightsideEnable {
   /*right: 24% !important;*/
   /* left: -webkit-calc((100% - 31em) + 3px) !important; */
}

@media (max-height:800px) {
.participantStatusWithRightsideEnable {
     /*right: 32.5% !important;*/
  }
}

/* @media (min-height:900px) {
.participantStatusNew {
     right: 0.7%;
  }
} */

.displaynone {
    display: none !important;
}

.remoteVideo.roomMode {
  border-radius: 0.25rem;
}

.remoteVideo.screenShareMode {
  border-radius:20px !important;
}

.remoteVideo.enabled {
  display: block !important;
  /* margin: 1%; */
  height: 100%;
    width: 100%;
  /*margin-left: 0;
  width: 188px;
    height: 127px;*/
    background: #C4C4C4;
    position: relative;
    border-radius: 1.2vw;
    border: 3px solid var(--color_border_color_light);
}
.remoteVideo .rosterEnabled{
  display: flex !important;
  /* margin: 1%; */
  height: 100%;
    width: 100%; 
}
.tileVideo{
  height: 100%;
  display: block;
  position: unset;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 8;
  width: 100%;
  border-radius: 1vw;
  /* border:2px solid var(--color_border_color_light); */
}

.participantControlTileView{
  position: absolute;
  /* float: right; */
  /* bottom: 40px; */
  top: 0;
  /* right: 0; */
  /* left: 45px; */
  width: 100%;
  height: 0;
  z-index: 8;
}
.participantControlDivTileView{
    /* display: flex; */
    /* flex-direction: row; */
    float: right;
    /* position: relative; */
    width: 6vw;
    height: 2.5vw;
    /* left: 188px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* top: 393px; */
    background: var(--color_white);
    border-radius: 0 1vw 0 1vw;
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    color: #434358;
}
.video {
  display: block;
  position: unset;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 8;
  width: 100%;
  /* border: 2px solid var(--color_border_color_light); */
  height: 11vw;
  border-radius: 1vw;
}

.ddtt {
    font-size: 30px;
    background: red;
}

.removefullscreen {
  float: right;
    position: absolute;
    z-index: 9;
    right: 0;
    font-size: 25px;
    margin-right: -25%;
}


.video1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  object-fit: cover;
  z-index: 8;
}


.video i {
    color: var(--color_black);
    top: 40%;
    float: left;
    position: absolute;
    left: 4%;
}

.attname {
    color: var(--color_black);
    top: 0%;
    float: left;
    position: absolute;
    left: 4%;
}

.muted {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

.marginlefticon i{
  margin-left: 6px !important;
    float: left;
}

.manageheight i{ 
  line-height: 21px;
    margin: -1px 2px 0px 0px;
}

.nameplate {
  z-index: 1;
  align-items: center;
  position: absolute;
  max-width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color_black_medium_opacity);
  backdrop-filter: blur(0.5rem);
}

.remoteVideo.roomMode .nameplate {
  display: flex;
}

.remoteVideo.screenShareMode .nameplate {
  display: none;
}

.remoteVideo.medium .nameplate {
  padding: 0.25rem 0.3rem 0.25rem 0.5rem;
  bottom: 0.25rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
}

.remoteVideo.large .nameplate {
  padding: 0.5rem 0.75em 0.5em 1rem;
  bottom: 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  z-index: 9;
}

.marginleft {
  left: 12%;
}

.position {
  position: relative !important;
  display: block;
  width: 100%;
  height: 11vw;
  /* border-radius: 0.25rem; */
  border-radius: 1vw;
  object-fit: cover;
  /* border: 2px solid var(--color_border_color_light); */
}
.fullscreen {
    position: fixed !important;
    /*z-index: 9;*/
    padding: 0% !important;
    margin: 0% !important;
    border-radius: 0px !important;
}


/* .fullscreen1 {
    position: fixed;
    width: -webkit-calc((100% - 20em) + 3px) !important;
} */

.fullscreenTwo {
  position: absolute;
    bottom: 10% !important;
    left: 22%;
}

.displaynone {
    display: none !important;
}

.margintop {
    
    left: 28%;
    margin-top: 7px;
}

.margintop1 {
    left: 100px;
    bottom: 75px !important;
}

.remoteVideo.small .nameplate {
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled .nameplate {
  display: none;
}

.remoteVideo.screenShareMode.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

/* .name {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  line-height: 26px;
  margin-left: 30px;
} */

.muted {
  flex: 0 0 1.25rem;
  width: 1.25rem;
  text-align: center;
}

.remoteVideo.small .name + .muted {
  margin-left: 0.25rem;
  text-align: center !important;
}

.remoteVideo.medium .name + .muted {
  margin-left: 0.25rem;
text-align: center !important;

}

.remoteVideo.large .name + .muted {
  margin-left: 0.5rem;
}

.raisedHand {
  z-index: 1;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  font-size: 2rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}
.three-dots{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.screenShareViewModeParticipants{
  bottom: 40px;
}
.screenShareViewModenameStatus{
  width: 93px;
  height: 40px;
  border-radius: 0 20px 0 20px;
  font-size: 12px;
}
.positionScreenShareView{
  height: 140px;
  border-radius: 20px;
}