/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .contentVideo {
  display: block;
  /* background-color: var(--color_black); */
  width: 100%;
  height: 100%;
  /* padding: 2%; */
  /* border-radius: 117px; */
  z-index: 1;
  /* float: right; */
  position: relative;
}

.displaynone {
    display: none !important;
}

.screenShareVideo {
  display: block;
  width: 100%;
  /* border-radius: 10px; */
  height: 100%;
  object-fit: contain;
}

.fullscreenShare {
  position: fixed !important;
  z-index: 0 !important;
}

.smallscreen {
  width: 100%;
  height: 100%;
  padding-top: 0;

}

/* .smallscreenChildWindow {
  height: 124px !important;
  padding-top: 2px  !important;
} */

.fsleft {
  margin-left: 84px !important;
}
.fstop {
  margin-top: -137px !important; 
}

.width {
  width: 97.7% !important;
}

.fstopsc {
  margin-top: -151px !important;  
}

@media (min-height: 900px) {  
  .manageWidthWhileRightSideEnagle {
      /*width: 97% !important;*/
  }
  .contentVideo .video{
        /* width: 86%; */
        width: 100%;
        /* margin: 0 auto; */
  }
  .fullscreenButton{
      bottom: 8% !important;
    left: 16% !important;
  }
}
/* 
 @media (max-height: 800px) {  
  .contentVideo .video{
        width: 100%;       
  }
}  */