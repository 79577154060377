.chatStandAlonwDivContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color_white);
}
.divContainer{
  display: block;
  flex-direction: column;
  width: 100%;
  height: 90%;
  background-color:  var(--color_white);
  text-transform: capitalize;
  font-family: var(--font-family-Futura-Bk-BT);
  overflow-y: scroll;
}
.attendeesTabtitle {
  /* float: left;
  width: 100%;
  margin-left: 15px; */
  float: left;
  height: 12%;
  width: 100%;
  border-bottom: 2px solid #9babc5;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;
}
.tabtitleSmallScreen{
  float: left;
  height: 10%;
  width: 100%;
  border-bottom: 2px solid #9babc5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.attendeesTabtitle .titlename {
  margin:0;
}
.tabtitleSmallScreen .titlename{
  margin: 0;
}
.tabtitleSmallScreen h3.titlename span{
  font-size: 1em;
  margin-left: .5rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.2rem;
}
.attendeesTabtitle h3.titlename span{
  font-size: 1em;
  margin-left: .5rem;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.2rem;
}
.tabtitleSmallScreen h3{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  width: 70%;
  line-height: 36px;
  /* text-align: center; */
  color: var(--color_primary);
}
.attendeesTabtitle h3 {
  font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 1.7vmax;
    width: 70%;
    line-height: 1.7vmax;
    text-align: left;
    color: var(--color_primary);
}

.attendeesTabtitle h3 a {
  float: right;
  /* margin-right: 30px; */
  cursor: pointer;
}
.TitleHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 4vw;
  height: 4vw;
  background: var(--color_white);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 1.5vw;
}
.TitleHeader img{
  object-fit: contain;
  width: 1.5vw;
  height: 1.5vw;
  transform: rotate(180deg);
}
.chatAttendeeListMainDiv{
  position: relative;
  background: var(--color_white);
  width: 100%;
  height: 60px;
  border-bottom: 2px solid #9babc5;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  cursor: pointer;
}
.chatusersNameDiv{
  display: flex;
  align-items: center;
  width: 30%;
  height: 100%;
  justify-content: center;
}
.chatusersName{
  background-color: var(--color_secondary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: var(--color_white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatAttendeeList{
  color: var(--color_secondary);
  width: 70%;
  display: flex;
  align-items: center;
  /* background: #9babc5; */
  font-family: var(--font-family-Futura-Md-BT);

}
.chatinput{
    width: 100%;
    height: 40px;
}
/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .userlistMain {

    display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
  }
  .userlistMainSmallScreen{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%
  }
  
  .userlistMain .userlist{
    display: flex;
    height: 15%;
    align-items: center;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 2px solid #9babc5;
    max-width: 23vw;
    min-width: 10vw;
    width: 100%;
  }
  .userlistMainSmallScreen .userlist{
    display: flex;
    height: 10%;
    align-items: center;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 2px solid #9babc5;
    max-width: 100vw;
    width: 100%;
  }
  .userlistMain .fullScreenModeUserList{
    display: flex;
    height: 10%;
    align-items: center;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 2px solid #9babc5;
    max-width: 25vw;
    min-width: 10vw;
    width: 100%;;
  }
  .userlistMain .userlist::-webkit-scrollbar {
    height: 5px;
  }
  .userlistMain .fullScreenModeUserList::-webkit-scrollbar {
    height: 5px;
  }
  .userlistMain .userlist::-webkit-scrollbar-thumb {
    background: #9babc5;
    border-radius: 5px;
  }
  .userlistMain .fullScreenModeUserList::-webkit-scrollbar-thumb {
    background: #9babc5;
    border-radius: 5px;
  }
  .userlistMainSmallScreen .userlist::-webkit-scrollbar {
    height: 5px;
  }
  .userlistMainSmallScreen .userlist::-webkit-scrollbar-thumb {
    background: #9babc5;
    border-radius: 5px;
  }
  /* .userlistMain .userlist{
      display: flex;
      flex-direction: column;    
      overflow: scroll;
      border-right: 2px solid #9babc5;
      width: 15%;
   } */
  
   .userlistMainSmallScreen .userlist .user.active{    
      background: repeating-linear-gradient(var(--color_primary),var(--color_primary), var(--color_black) 100px);
   }
   .userlistMain .userlist .user.active{    
    background: repeating-linear-gradient(var(--color_primary),var(--color_primary), var(--color_black) 100px);
  }
   .userlistMain .fullScreenModeUserList .user.active{    
    background: repeating-linear-gradient(var(--color_primary),var(--color_primary), var(--color_black) 100px);
  }
   .userlistMain .userlist .user{
    margin: 0.5vw;
    height: 3.5vw;
    display: flex;
    padding: 1.5vw;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5vw;
    width: 3.5vw;
    background: #c4c4c4;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.8vw;
    cursor: pointer;
    font-family: var(--font-family-Futura-Bk-BT);
    color: var(--color_white);
   }
  
  
   .userlistMain .fullScreenModeUserList .user{
    margin: 0.5vw;
    height: 3.5vw;
    display: flex;
    padding: 1.5vw;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5vw;
    width: 3.5vw;
    background: #c4c4c4;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.8vw;
    cursor: pointer;
    font-family: var(--font-family-Futura-Bk-BT);
    color: var(--color_white);
   }
   .userlistMainSmallScreen .userlist .user{
    margin: 5px;
    height: 35px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    width: 35px;
    background: #c4c4c4;
    text-align: center;
    vertical-align: middle;
    border-radius: 6px;
    cursor: pointer;
    font-family: var(--font-family-Futura-Bk-BT);
    color: var(--color_white);
   }
   .userlist .user img{
      width: 30px;
   }
   .fullScreenModeUserList .user img{
    width: 30px;
  }
  
   .user .typing {
      float: left;
      position: relative;
      margin: -7px 0 0 3px;
   }
  .pubGroup{
    display: flex;
    flex-direction: column;
  }
   .istyping {
    float: left;
    position: absolute;
    bottom: 5.1vw;
    padding: 0 0.5vw 0 1.5vw;
    /* opacity: 0.5; */
    font-size: 14px;
  
   }  
  .istyping span{
    font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  line-height: 24px;
  /* identical to box height */
  
  
  color: #9babc5;
  }
   .istyping img {
      position: absolute;
      height: 10px;
      width: 35px;
      margin: 8px 0px 0px 5px;
   }
  
   .typinggroup {
    position: relative;
    /* margin: 2.2vw 0 0 0.2vw; */
    height: 10px;
   }
  
  .chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    overflow: scroll;
    /* display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    position: absolute; */
  }
  
  .margintop {
  }
  
  .bottom {
    color: red;
    position: fixed;
    bottom: 84px;
    margin-bottom: -29px;
    background: green;
    height: 30px;
    width: 100%;
  }
  
   .userlist .countuser {
    position: absolute;
    font-size: 10px;
    background: repeating-linear-gradient(var(--color_primary),var(--color_primary), var(--color_black) 100px);
    color: var(--color_white);
    /* top: 10px; */
    padding: 1px;
    min-width: 15px;
    border-radius: 50%;
    margin: -11px 0px 0px 13px;
    text-align: center;
    height: 15px;
    width: 15px;
    line-height: 14px;
   }
   .fullScreenModeUserList .countuser {
    position: absolute;
    font-size: 10px;
    background: repeating-linear-gradient(var(--color_primary),var(--color_primary), var(--color_black) 100px);
    color: var(--color_white);
    /* top: 10px; */
    padding: 1px;
    min-width: 15px;
    border-radius: 50%;
    margin: -11px 0px 0px 13px;
    text-align: center;
    height: 15px;
    width: 15px;
    line-height: 14px;
   }
  
  .messages {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      /* padding-top: 0.5rem; */
      /* height: calc(95vh - 14.7rem); */
      height: 97%;
  }
  .messages::-webkit-scrollbar {
    width: 5px;
  }
  .messages::-webkit-scrollbar-thumb {
    background: #9babc5;
    border-radius: 5px;
  }
  
  .messageschild {
    flex: 1 1 auto;
    overflow-y: auto;
    /* padding-top: 0.5rem; */
    width: 100%;
    height: 97%;
  }
  .messageschild::-webkit-scrollbar {
    width: 5px;
  }
  .messageschild::-webkit-scrollbar-thumb {
    background: #9babc5;
    border-radius: 5px;
  }
  @media screen and (min-height: 900px) {
    .messages {
      height: calc(95vh - 15.2rem);
    }
  }
  
  .messageWrapperSender {
    padding: 0.5vw 0.25vw;
    float: right;
  }
  .messageWrapperSenderSmallScreen{
    padding: 0;
    float: right;
  }
  .messageWrapperReceiverSmallScreen{
    padding: 0;
    float: left;
  }
  .messageWrapperReceiver {
    padding: 0.5vw 1.25vw;
  }
  .messageWrapperSenderSmallScreen .left{
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    /* width: max-content; */
    right: 10px;
    min-width: 100px;
    background: var(--color_primary);
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
    border-radius: 10px 0 10px 10px;
  }
  .messageWrapperSender .left {
    /* display: flex;
    flex-direction: column;
    padding: 0px;
    position: relative;
    width: auto;
    left: -4px;
    top: 0px;
    flex: none;
    order: 0;
    align-self: center; */
    display: flex;
      flex-direction: column;
      padding: 0.4vw 1vw 0.4vw 1vw;
      /* width: max-content; */
      right: 1vw;
      min-width: 9vw;
      background: var(--color_primary);
      box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
      border-radius: 1vw 0 1vw 1vw;
  }
  .messageWrapperReceiverSmallScreen .left{
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--color_white);
    min-width: 100px;
    /* width: max-content; */
    padding: 5px 10px 5px 10px;
    left: 10px;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
    border-radius: 0 10px 10px 10px;
  }
  .messageWrapperReceiver .left {
    /* display: flex;
    flex-direction: column;
    padding: 0px;
    position: relative;
    width: auto;
    left: 4px;
    top: 0px;
    flex: none;
    order: 0;
    align-self: center; */
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--color_white);
    min-width: 8vw;
    /* width: max-content; */
    padding: 0.4vw 1vw 0.4vw 1vw;
    left: 1vw;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
    border-radius: 0 1vw 1vw 1vw;
  }
  .messageWrapperSenderSmallScreen .right .name{
    text-transform: capitalize;
  }
  .messageWrapperSender .right .name {
    text-transform: capitalize;
  }
  .messageWrapperReceiverSmallScreen .right .name{
    text-transform: capitalize;
  }
  .messageWrapperReceiver .right .name {
    text-transform: capitalize;
  }
  .messageWrapperSenderSmallScreen .right{
    position: relative;
    order: 1;
    float: right;
    border-radius: 30%;
    margin-left: 10px;
    height:30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color_white);
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .messageWrapperSender .right {
    position: relative;
    order: 1;
    float: right;
    border-radius: 30%;
    margin-left: 1vw;
    height: 3vw;
    width: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color_white);
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 1.6vw;
    line-height: 2vw;
  }
  .messageWrapperReceiverSmallScreen .right{
    position: relative;
    order: 1;
    float: left;
    border-radius: 30%;
    margin: 0 auto;
    height: 30px;
    width: 30px;
    color: var(--color_white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
  .messageWrapperReceiver .right {
    position: relative;
    order: 1;
    float: left;
    border-radius: 30%;
    margin: 0 auto;
    height: 3vw;
    width: 3vw;
    color: var(--color_white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.6vw;
    line-height: 2vw;
  }
  .messageWrapperSenderSmallScreen .senderName{
    text-align: right;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #9babc5;
  }
  .messageWrapperSender .senderName {
    /* position: relative;
    height: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #383838;
    flex: none;
    order: 0;
    align-self: flex-end;
    margin: 1px 2px; */
    text-align: right;
    font-family:var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: #9babc5;
  }
  .messageWrapperReceiverSmallScreen .senderName{
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color_primary);
  }
  .messageWrapperReceiver .senderName {
    /* position: relative;
    height: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #383838;
    flex: none;
    order: 0;
    align-self: flex-start;
    margin: 1px 2px; */
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: var(--color_primary);
  }
  .messageWrapperSenderSmallScreen .message {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--color_white);
    align-self: flex-end;
  }
  .messageWrapperSender .message {
    /* width: 210px;
    display: table;
    flex-direction: row;
    padding: 6px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    background: #7aafc470;
    border-radius: 6px;
    flex: none;
    order: 2;
    align-self: flex-end;
    margin: 5px 5px;
    text-align: justify;
    color: black;
    font-size: 15px; */
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: var(--color_white);
    align-self: flex-end;
  }
  .messageWrapperReceiverSmallScreen .message{
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #9babc5;
  }
  .messageWrapperReceiver .message {
    /* width: 210px;
    display: table;
    flex-direction: row;
    padding: 6px;
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    background: #ffa50070;
    border-radius: 6px;
    flex: none;
    order: 2;
    align-self: flex-start;
    margin: 5px 5px;
    text-align: justify;
    color: black;
    font-size: 15px; */
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: #9babc5;
  }
  
  .messageWrapperSender .date {
    position: static;
    width: 45px;
    height: 16px;
    left: 45px;
    top: 97px;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    text-align: right;
    /* Black/30% */
    color: rgba(0, 0, 0, 0.8);
    /* Inside Auto Layout */
    flex: none;
    order: 3;
    align-self: flex-end;
    margin: 0px 3px;
  }
  .messageWrapperReceiverSmallScreen .date{
    position: static;
    width: 45px;
    height: 16px;
    left: 45px;
    top: 97px;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    text-align: right;
    /* Black/30% */
    color: rgba(0, 0, 0, 0.8);
    /* Inside Auto Layout */
    flex: none;
    order: 3;
    align-self: flex-start;
    margin: 0px 3px;
  }
  .messageWrapperReceiver .date {
    position: static;
    width: 45px;
    height: 16px;
    left: 45px;
    top: 97px;
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    text-align: right;
    /* Black/30% */
    color: rgba(0, 0, 0, 0.8);
    /* Inside Auto Layout */
    flex: none;
    order: 3;
    align-self: flex-start;
    margin: 0px 3px;
  }
  .messageWrapperSenderSmallScreen .timecolor{
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    /* margin-left: 50px; */
    line-height: 17px;
    color: #9babc5;
  }
  .messageWrapperSender .timecolor {
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    /* margin-left: 50px; */
    line-height: 17px;
    color: #9babc5;
  }
  .messageWrapperReceiverSmallScreen .timecolor{
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    margin-left: 40px;
    line-height: 17px;
    color: #9babc5;
  }
  .messageWrapperReceiver .timecolor {
    font-family: var(--font-family-Futura-Bk-BT);
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    margin-left: 4.1vw;
    line-height: 17px;
    color: #9babc5;
  }
  
  .blueColor {
    background: var(--color_secondary);
  }
  .orangeColor {
    background: orange;
  }
  
  .messageWrapper.raiseHand .message {
    font-size: 2rem;
  }
  
  .chatInput {
    /*flex: 0 0 auto;*/
    /* float: left;
    position: fixed;
    bottom: 48px; */
    position: relative;
      width: 100%;
      height: 10%;
  }
  .messageWrapperReceiverDiv{
    width: 100%;
    min-height: 14%;
    display: flex;
    padding:0.5vw;
  }
  .pubMessageDiv{
    width: 100%;
    min-height: 14%;
    padding:0.5vw;
  }
  
/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .chatInput {
  /* position: absolute;
  width: 320px;
  height: 48px;
  background: var(--color_white);
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 12px 0px; */
  width: 100%;
  height: 10%;
}

.form {

  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color_white);
  border-radius: 0.25rem;
}

.input {
  /* flex: 1 1 auto;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  width: 100%;
    float: left;
    bottom: 0px;
    position: absolute;
    border: 10px solid #9babc5;
    border-radius: 10px 10px 0px 0px; */
    width: 80%;
    height: 70%;
    background: #fff;
    padding: 0.4vw 1.1vw 0.4vw 1.1vw;
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
    border: none;
    border-radius: 20px;
}

.input::placeholder {
  color: #9babc5;
}

.raiseHandButton {
  flex: 0 0 auto;
  font-size: 1.5rem;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin-top: 0.25rem;
  float: right;
}

.raiseHandButton span {
  transition: all 0.05s;
  filter: grayscale(100%);
}

.raiseHandButton span:hover {
  filter: grayscale(0);
}

.raiseHandButton.raised span {
  filter: grayscale(0) !important;
}

/* .attachIcon {
  position: absolute;
  width: 16px;
  height: 16px;
background: url('../public/icons/icon-attach.png');
} */
.inputSpan{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.sendMessageButtton{
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background: var(--color_primary);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: none !important;
}
.sendMessageButtton img{
  object-fit: contain;
  width: 25px;
  height: 25px;
}
.msgCounter{
  right: 60px;
  top: 20px;
  height: 20px;
  background: var(--color_primary);
  border-radius: 50%;
  justify-content: center;
  width: 20px;
  max-width: 20px;
  font-size: 12px;
  line-height: 1vmin;
  position: absolute;
  display: flex;
  align-items: center;
  font-family: var(--font-family-Futura-Md-BT);
  color: var(--color_white);
  padding: 12px;
}
.noFoundData{
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9babc5;
  margin: 20%;
}
.lastActiveTime{
  color: var(--color_light_grey);
  right: 5px;
  position: absolute;
  top: 24px;
  font-size: 12px;
  font-family: var(--font-family-Futura-Md-BT);
} 