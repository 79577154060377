/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .localVideo {
  display: none;
  /* width: calc(var(--local_video_container_height) - 0.5rem);
  height: calc(var(--local_video_container_height) - 0.5rem);
  background: var(--color_black);
  border-radius: 0.25rem;
  overflow: hidden; */
}
.videoTileview {
  display: block;
  width: calc(var(--local_video_container_height) - 0.5rem);
  height: calc(var(--local_video_container_height) - 0.5rem);
  /* background: var(--video-background-color); */
  border-radius: 1.2vw;
  border: 3px solid var(--color_border_color_light);
  overflow: hidden;
  display: block !important;
  /* margin: 0px -2px 4px 4px; */
  z-index: 4;
  width: 100%;
  height: 100%;
  position: relative;
}

.localVideo.enabled {
  display: block !important;
      margin-top: 4px;
      z-index: 4;
    position: relative;

}

.setbgcolor {
    /* background: #021b56; */
    background-color: #C4C4C4;
}

.video {
  /* display: block;
    width: 99%;
    height: 131px;
    border-radius: 4px;
    object-fit: cover;
    margin-left: -1px; */
    display: block;
    width: 100%;
    height: 11vw;
    /* border-radius: 0.25rem; */
    border-radius: 1vw;
    object-fit: cover;
    /* border: 2px solid var(--color_border_color_light); */
}
.screenShareModeVideo{
  height: 140px;
  border-radius: 20px;
}
.localVideoTileView{
  display: block;
  width: 100%;
  height: 100%;
  /* border-radius: 0.25rem; */
  border-radius: 1vw;
  object-fit: cover;
  /* border: 2px solid var(--color_border_color_light); */
}
.namemain {
  /* float: left;
  position: relative;
  bottom: 30px;
  width: 90%;
  left: 2%;
  height: 0;
  z-index: 2; */
  float: left;
  position: relative;
  /* bottom: 40px; */
  bottom: 2.5vw;
  width: 100%;
  height: 0;
  z-index: 2;
}

.namemain .host {
  float: left;
    width: 20%;
    background: #207ada;
    border-radius: 5px 0px 0px 6px;
    padding: 2px;
    text-align: center;
    font-size: 14px;
    margin-left: -2px;
}


.namemain .localname {
    /* width: 93px;
    height: 40px; */
    width: 6vw;
    height: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color_white);
    /* border-radius: 0px 20px 0px 18px; */
    border-radius: 0 1vw 0 1vw;
    font-family: var(--font-family-Futura-Md-BT);
    font-style: normal;
    font-weight: 500;
    /* font-size: 20px; */
    /* line-height: 24px; */
    font-size: 1.2vw;
    line-height: 1.2vw;
    text-align: center;
    color: #434358;
}
.attendeeNameScreenShareView{
  font-size: 24px;
}
.screenShareNameMain{
  bottom: 40px;
}
.screenShareNameMain .localname{
  width: 93px;
    height: 40px;
    font-size: 12px;
    border-radius: 0 20px;
}
.screenShareModeVideoView{
  border-radius: 20px !important;
}
.micIcon{
  background: var(--color_white);
  width: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 2.5vw;
  border-radius: 0 1vw 0 1vw;
  position: absolute;
  top: 0;
  right: 0;
  color: grey;
}
.screenShareModeMicIcon{
  background: var(--color_white);
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  border-radius: 0 20px 0 20px;
  position: absolute;
  top: 0;
  right: 0;
  color: grey;
}
.screenShareModeMicIcon i{
  font-size: 12px !important;
}