/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .userlistMain {

  display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
}
.userlistMainSmallScreen{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%
}

.userlistMain .userlist{
  display: flex;
  height: 15%;
  align-items: center;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 2px solid #E4E9F0;
  max-width: 23vw;
  min-width: 10vw;
  width: 100%;
}
.userlistMainSmallScreen .userlist{
  display: flex;
  height: 10%;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 2px solid #E4E9F0;
  max-width: 100vw;
  width: 100%;
}
.userlistMain .fullScreenModeUserList{
  display: flex;
  height: 10%;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 2px solid #E4E9F0;
  max-width: 25vw;
  min-width: 10vw;
  width: 100%;;
}
.userlistMain .userlist::-webkit-scrollbar {
  height: 5px;
}
.userlistMain .fullScreenModeUserList::-webkit-scrollbar {
  height: 5px;
}
.userlistMain .userlist::-webkit-scrollbar-thumb {
  background: #9BABC5;
  border-radius: 5px;
}
.userlistMain .fullScreenModeUserList::-webkit-scrollbar-thumb {
  background: #9BABC5;
  border-radius: 5px;
}
.userlistMainSmallScreen .userlist::-webkit-scrollbar {
  height: 5px;
}
.userlistMainSmallScreen .userlist::-webkit-scrollbar-thumb {
  background: #9BABC5;
  border-radius: 5px;
}
/* .userlistMain .userlist{
    display: flex;
    flex-direction: column;    
    overflow: scroll;
    border-right: 2px solid #E4E9F0;
    width: 15%;
 } */

 .userlistMainSmallScreen .userlist .user.active{    
    background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
 }
 .userlistMain .userlist .user.active{    
  background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
}
 .userlistMain .fullScreenModeUserList .user.active{    
  background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
}
 .userlistMain .userlist .user{
  margin: 0.5vw;
  height: 3.5vw;
  display: flex;
  padding: 1.5vw;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5vw;
  width: 3.5vw;
  background: var(--video-background-color);
  text-align: center;
  vertical-align: middle;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: var(--font-family-Futura-Bk-BT);
  color: var(--color_white);
  text-transform: capitalize;

 }


 .userlistMain .fullScreenModeUserList .user{
  margin: 0.5vw;
  height: 3.5vw;
  display: flex;
  padding: 1.5vw;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5vw;
  width: 3.5vw;
  background: var(--video-background-color);
  text-align: center;
  vertical-align: middle;
  border-radius: 0.8vw;
  cursor: pointer;
  font-family: var(--font-family-Futura-Bk-BT);
  color: var(--color_white);
  text-transform: capitalize;
 }
 .userlistMainSmallScreen .userlist .user{
  margin: 5px;
  height: 35px;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  width: 35px;
  background: var(--video-background-color);
  text-align: center;
  vertical-align: middle;
  border-radius: 6px;
  cursor: pointer;
  font-family: var(--font-family-Futura-Bk-BT);
  color: var(--color_white);
 }
 .userlist .user img{
    width: 30px;
 }
 .fullScreenModeUserList .user img{
  width: 30px;
}

 .user .typing {
    float: left;
    position: relative;
    margin: -7px 0 0 3px;
 }
.pubGroup{
  display: flex;
  flex-direction: column;
}
 .istyping {
  float: left;
  position: absolute;
  bottom: 70px;
  /* padding: 0px 1px 1px 5px; */
  opacity: 0.5;
  font-size: 14px;

 }  
.istyping span{
  font-family: var(--font-family-Futura-Bk-BT);
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 24px;
/* identical to box height */


color: #9BABC5;
}
 .istyping img {
    position: absolute;
    height: 10px;
    width: 35px;
    margin: 8px 0px 0px 5px;
 }

 .typinggroup {
  position: relative;
  /* margin: 2.2vw 0 0 0.2vw; */
  height: 10px;
 }

.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  /* display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  position: absolute; */
}

.margintop {
}

.bottom {
  color: red;
  position: fixed;
  bottom: 84px;
  margin-bottom: -29px;
  background: green;
  height: 30px;
  width: 100%;
}

 .userlist .countuser {
  position: absolute;
  font-size: 10px;
  background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
  color: var(--color_white);
  /* top: 10px; */
  padding: 1px;
  min-width: 15px;
  border-radius: 50%;
  margin: -11px 0px 0px 13px;
  text-align: center;
  height: 15px;
  width: 15px;
  line-height: 14px;
 }
 .fullScreenModeUserList .countuser {
  position: absolute;
  font-size: 10px;
  background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
  color: var(--color_white);
  /* top: 10px; */
  padding: 1px;
  min-width: 15px;
  border-radius: 50%;
  margin: -11px 0px 0px 13px;
  text-align: center;
  height: 15px;
  width: 15px;
  line-height: 14px;
 }

.messages {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    /* padding-top: 0.5rem; */
    /* height: calc(95vh - 14.7rem); */
    height: 97%;
}
.messages::-webkit-scrollbar {
  width: 5px;
}
.messages::-webkit-scrollbar-thumb {
  background: #9BABC5;
  border-radius: 5px;
}

.messageschild {
  flex: 1 1 auto;
  overflow-y: auto;
  /* padding-top: 0.5rem; */
  width: 100%;
  height: 97%;
}
.messageschild::-webkit-scrollbar {
  width: 5px;
}
.messageschild::-webkit-scrollbar-thumb {
  background: #9BABC5;
  border-radius: 5px;
}
@media screen and (min-height: 900px) {
  .messages {
    height: calc(95vh - 15.2rem);
  }
}

.messageWrapperSender {
  padding: 0.5vw 0.25vw;
  float: right;
}
.messageWrapperSenderSmallScreen{
  padding: 0;
  float: right;
}
.messageWrapperReceiverSmallScreen{
  padding: 0;
  float: left;
}
.messageWrapperReceiver {
  padding: 0.5vw 1.25vw;
}
.messageWrapperSenderSmallScreen .msgLeft{
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  /* width: max-content; */
  right: 10px;
  min-width: 100px;
  background: var(--color_primary);
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px 0 10px 10px;
}
.messageWrapperSender .msgLeft {
  /* display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  width: auto;
  left: -4px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center; */
  display: flex;
    flex-direction: column;
    padding: 0.4vw 1vw 0.4vw 1vw;
    /* width: max-content; */
    right: 1vw;
    min-width: 9vw;
    background: var(--color_primary);
    box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
    border-radius: 1vw 0 1vw 1vw;
}
.messageWrapperReceiverSmallScreen .msgLeft{
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--color_white);
  min-width: 100px;
  /* width: max-content; */
  padding: 5px 10px 5px 10px;
  left: 10px;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0 10px 10px 10px;
}
.messageWrapperReceiver .msgLeft {
  /* display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  width: auto;
  left: 4px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center; */
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--color_white);
  min-width: 8vw;
  /* width: max-content; */
  padding: 0.4vw 1vw 0.4vw 1vw;
  left: 1vw;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0 1vw 1vw 1vw;
}
.messageWrapperSenderSmallScreen .msgRight .name{
  text-transform: capitalize;
}
.messageWrapperSender .msgRight .name {
  text-transform: capitalize;
}
.messageWrapperReceiverSmallScreen .msgRight .name{
  text-transform: capitalize;
}
.messageWrapperReceiver .msgRight .name {
  text-transform: capitalize;
}
.messageWrapperSenderSmallScreen .msgRight{
  position: relative;
  order: 1;
  float: right;
  border-radius: 30%;
  margin-left: 10px;
  height:30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color_white);
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.messageWrapperSender .msgRight {
  position: relative;
  order: 1;
  float: right;
  border-radius: 30%;
  margin-left: 1vw;
  height: 3vw;
  width: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color_white);
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 1.6vw;
  line-height: 2vw;
}
.messageWrapperReceiverSmallScreen .msgRight{
  position: relative;
  order: 1;
  float: left;
  border-radius: 30%;
  margin: 0 auto;
  height: 30px;
  width: 30px;
  color: var(--color_white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.messageWrapperReceiver .msgRight {
  position: relative;
  order: 1;
  float: left;
  border-radius: 30%;
  margin: 0 auto;
  height: 3vw;
  width: 3vw;
  color: var(--color_white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.6vw;
  line-height: 2vw;
}
.messageWrapperSenderSmallScreen .senderName{
  text-align: right;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #CCC;
}
.messageWrapperSender .senderName {
  /* position: relative;
  height: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #383838;
  flex: none;
  order: 0;
  align-self: flex-end;
  margin: 1px 2px; */
  text-align: right;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  line-height: 1.5vw;
  color: #CCC;
}
.messageWrapperReceiverSmallScreen .senderName{
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--color_primary);
}
.messageWrapperReceiver .senderName {
  /* position: relative;
  height: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #383838;
  flex: none;
  order: 0;
  align-self: flex-start;
  margin: 1px 2px; */
  font-family: var(--font-family-Futura-Md-BT);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.5vw;
  color: var(--color_primary);
}
.messageWrapperSenderSmallScreen .message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--color_white);
  align-self: flex-end;
}
.messageWrapperSender .message {
  /* width: 210px;
  display: table;
  flex-direction: row;
  padding: 6px;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  background: #7aafc470;
  border-radius: 6px;
  flex: none;
  order: 2;
  align-self: flex-end;
  margin: 5px 5px;
  text-align: justify;
  color: var(--color_black);
  font-size: 15px; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  line-height: 1.5vw;
  color: var(--color_white);
  align-self: flex-end;
}
.messageWrapperReceiverSmallScreen .message{
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #9BABC5;
}
.messageWrapperReceiver .message {
  /* width: 210px;
  display: table;
  flex-direction: row;
  padding: 6px;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  background: #ffa50070;
  border-radius: 6px;
  flex: none;
  order: 2;
  align-self: flex-start;
  margin: 5px 5px;
  text-align: justify;
  color: var(--color_black);
  font-size: 15px; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2vw;
  line-height: 1.5vw;
  color: #9BABC5;
}

.messageWrapperSender .date {
  position: static;
  width: 45px;
  height: 16px;
  left: 45px;
  top: 97px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: right;
  /* Black/30% */
  color: rgba(0, 0, 0, 0.3);
  /* Inside Auto Layout */
  flex: none;
  order: 3;
  align-self: flex-end;
  margin: 0px 3px;
}
.messageWrapperReceiverSmallScreen .date{
  position: static;
  width: 45px;
  height: 16px;
  left: 45px;
  top: 97px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: right;
  /* Black/30% */
  color: rgba(0, 0, 0, 0.3);
  /* Inside Auto Layout */
  flex: none;
  order: 3;
  align-self: flex-start;
  margin: 0px 3px;
}
.messageWrapperReceiver .date {
  position: static;
  width: 45px;
  height: 16px;
  left: 45px;
  top: 97px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: right;
  /* Black/30% */
  color: rgba(0, 0, 0, 0.3);
  /* Inside Auto Layout */
  flex: none;
  order: 3;
  align-self: flex-start;
  margin: 0px 3px;
}
.messageWrapperSenderSmallScreen .timecolor{
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  /* margin-left: 50px; */
  line-height: 17px;
  color: #9BABC5;
}
.messageWrapperSender .timecolor {
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  /* margin-left: 50px; */
  line-height: 17px;
  color: #9BABC5;
}
.messageWrapperReceiverSmallScreen .timecolor{
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-left: 40px;
  line-height: 17px;
  color: #9BABC5;
}
.messageWrapperReceiver .timecolor {
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  margin-left: 4.1vw;
  line-height: 17px;
  color: #9BABC5;
}

.blueColor {
  background: var(--color_secondary);
}
.orangeColor {
  background: orange;
}

.messageWrapper.raiseHand .message {
  font-size: 2rem;
}

.chatInput {
  /*flex: 0 0 auto;*/
  /* float: left;
  position: fixed;
  bottom: 48px; */
  position: relative;
    width: 100%;
    height: 10%;
}
.messageWrapperReceiverDiv{
  width: 100%;
  min-height: 14%;
  display: flex;
  padding:0.5vw;
}
.pubMessageDiv{
  width: 100%;
  min-height: 14%;
  padding:0.5vw;
}
