.NylasLogin{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.NylasLoginContainer{
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  padding: 2vw 3vw;
  /* transform: translate(-50%, -50%); */
  /* background-color: #242424 !important; */
  border-radius: 0.125rem;
  overflow: hidden;
}
.headerName{
  text-align: center;
margin: 0;
font-family: var(--font-family-Futura-Bk-BT);
font-style: normal;
font-weight: normal;
font-size: 4vh;
line-height: 4vw;
color: var(--color_primary);
}
p{
font-family: var(--font-family-Futura-Bk-BT);
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 24px;
color: #9BABC5;
}
.NylasLoginButton{
padding: 0.75vw !important;
font-size: 2.2vh !important;
line-height: 2.2vh;
font-weight: bold !important;
border: none;
border-radius: 1.5vw;
background: var(--color_primary);
color: white;
font-family: var(--font-family-Futura-Md-BT);
font-style: normal;
font-weight: 500;
font-size: 20px;
margin-top: 20px;
line-height: 24px;
width: 100%;
}
.login-bottom-link {
  /* margin: -28px 0px 0px 0px; */
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  /* margin-bottom: 20px; */
}
.loginLink {
  display: inline-block;
  color: white;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
}
.loginLinkBlackColor {
  color: #000 !important;
  text-decoration: none !important;
  font-family: var(--font-family-Futura-Bk-BT);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.rightSide {
  float: right;
  color: var(--color_primary) !important;
  font-style: normal;
  font-family: var(--font-family-Futura-Md-BT);
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 24px;
   
}