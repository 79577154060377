/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

 .tooltip[class~='rc-tooltip-placement-top'] div[class~='rc-tooltip-arrow'],
 .tooltip[class~='rc-tooltip-placement-topLeft'] div[class~='rc-tooltip-arrow'],
 .tooltip[class~='rc-tooltip-placement-topRight']
   div[class~='rc-tooltip-arrow'] {
   border-top-color: #fff;
 }
 
 .tooltip *[class~='rc-tooltip-inner'] {
   /* background-color: var(--background-color-gradiant-right); */
   /* font-size: 1rem; */
   /* border: 1px solid #9ba3b5; */
   background: #FFFFFF;
   box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.06);
   border-radius: 0 1.5vw;
   font-family: var(--font-family-Futura-Bk-BT);
   font-style: normal;
   font-weight: normal;
   font-size: 1.5vw;
   line-height: 2vw;
   color: #9BABC5;
 }
 